import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectVisualFilters,
  selectHotelName,
  selectPmsSync,
  selectTrendBookingDate,
  selectTrendTargetDate,
  selectWeeklyTrendEndDate,
  selectWeeklyTrendStartDate,
  selectEnableHotelGroups,
  selectReportType,
  selectIsDemoUser,
  selectTabValue,
} from 'modules/dashboard/selectors';
import {
  selectTrendTimeDateSelected,
  selectIsCumulative,
  selectTrendTimeCustomDate,
  selectTrendTimeEndDate,
  selectTrendTimeStartDate,
  selectTrendTimeWeeklyView,
  selectTrendSymmetricComparisonToggle,
  selectTrendTimeAlignment,
  selectTrendTimeDateComparison,
  selectIsSegmentDetailed,
  selectTrendSegmentChartBreakdown,
  selectTrendSegmentFocusOn,
  selectIsForecast,
  selectTrendTimeFilterList,
  selectActiveTrendTab,
  selectIsPercentageChange,
  selectTabularView,
  selectAdjustedStartDate,
  selectAdjustedEndDate,
  selectGranularity,
  selectIsTrendDeAggregate,
  selectTrendTimeTabularFilterList,
  selectIsMovingAverage,
  selectMovingAverage,
  selectTrendTimeSegmentFilterList,
  selectTrendTimeSegmentTabularFilterList,
} from 'modules/dashboard/components/tab-container/trend-tabs/selectors';
import {
  endOfMonth,
  isFirstDayOfMonth,
  isLastDayOfMonth,
  isSameDay,
  isSaturday,
  isSunday,
  lastDayOfYear,
  nextSaturday,
  previousSunday,
  startOfMonth,
  startOfYear,
} from 'date-fns';
import { trendActions } from 'modules/dashboard/components/tab-container/trend-tabs/slice';
import { KEYS, REPORT_TYPE, TAB_VALUE } from 'modules/dashboard/constants';
import {
  getBookingDateSchema,
  getCustomDateComparisonSchema,
  getGranularitySchema,
  getHotelGroupOrderSchema,
  getHotelIdSchema,
  getIsForecastSchema,
  getMovingAverageSchema,
  getPmsSyncToggleSchema,
  getRevenueForecastSchema,
  getRoomForecastSchema,
  getSegmentFocusOnSchema,
  getSymmetricComparisonSchema,
  getTargetDateSchema,
  getTimeSegmentBreakdownAndFocusOnSchema,
  getTrendTimeAlignmentAndDateComparisonSchema,
} from 'modules/dashboard/components/tab-container/inner-filter/functions';
import { getSelectedBreakdown } from 'modules/dashboard/functions';
import writeLog from 'modules/common/utils/filter-log';
import { storeVisualMapper } from 'modules/common/utils/filter-visual-mapper';
import VISUAL_MAPPER from 'modules/common/utils/visual-mapper';
import { getGranularity } from '../functions';
import { GRANULARITY } from '../constants';
/**
 * Custom hook to generate Trend Time widget filter schemas
 */
const useTrendTime = () => {
  const dispatch = useDispatch();
  //
  const alignment = useSelector(selectTrendTimeAlignment);
  const dateComparison = useSelector(selectTrendTimeDateComparison);
  const bookingDate = useSelector(selectTrendBookingDate);
  const targetDate = useSelector(selectTrendTargetDate);
  const hotelId = useSelector(selectHotelName);
  const visualFilters = useSelector(selectVisualFilters);
  const pmsSyncToggle = useSelector(selectPmsSync);
  const weeklyViewToggle = useSelector(selectTrendTimeWeeklyView);
  const targetStartDate = useSelector(selectWeeklyTrendStartDate);
  const targetEndDate = useSelector(selectWeeklyTrendEndDate);
  const symmetricComparisonToggle = useSelector(selectTrendSymmetricComparisonToggle);
  const isCumulative = useSelector(selectIsCumulative);
  const startDateComparison = useSelector(selectTrendTimeStartDate);
  const endDateComparison = useSelector(selectTrendTimeEndDate);
  const customDate = useSelector(selectTrendTimeCustomDate);
  const selectedDateComparison = useSelector(selectTrendTimeDateSelected);
  const focusOn = useSelector(selectTrendSegmentFocusOn);
  const chartBreakdown = useSelector(selectTrendSegmentChartBreakdown);
  const isDetailed = useSelector(selectIsSegmentDetailed);
  const enableHotelGroups = useSelector(selectEnableHotelGroups);
  const isTrendForecast = useSelector(selectIsForecast);
  const filterList = useSelector(selectTrendTimeFilterList);
  const tabularFilterList = useSelector(selectTrendTimeTabularFilterList);
  const activeTab = useSelector(selectActiveTrendTab);
  const reportType = useSelector(selectReportType);
  const isPercentageChange = useSelector(selectIsPercentageChange);
  const isTabular = useSelector(selectTabularView);
  const adjustedStartDate = useSelector(selectAdjustedStartDate);
  const adjustedEndDate = useSelector(selectAdjustedEndDate);
  const granularity = useSelector(selectGranularity);
  const isTrendDeAggregate = useSelector(selectIsTrendDeAggregate);
  const isDemoUser = useSelector(selectIsDemoUser);
  const isMovingAverage = useSelector(selectIsMovingAverage);
  const movingAverage = useSelector(selectMovingAverage);
  const tabValue = useSelector(selectTabValue);
  const TimeSegmentFilterList = useSelector(selectTrendTimeSegmentFilterList);
  const SegmentTabularFilterList = useSelector(selectTrendTimeSegmentTabularFilterList);
  //
  useEffect(() => {
    if (
      // tabValue !== TAB_VALUE.TREND_TIME ||
      // reportType !== REPORT_TYPE.TREND_TIME ||
      // activeTab !== REPORT_TYPE.TREND_TIME ||
      (!dateComparison && !alignment && !bookingDate) ||
      (dateComparison && dateComparison[0] === KEYS.CUSTOM && endDateComparison === null)
    ) {
      return;
    }
    const bookingDateFilter = getBookingDateSchema(
      visualFilters,
      bookingDate?.startDate,
      bookingDate?.endDate
    );
    const alignmentAndDateComparisonFilter = getTrendTimeAlignmentAndDateComparisonSchema(
      visualFilters,
      alignment?.id,
      dateComparison
    );
    const isForecastFilter = getIsForecastSchema(visualFilters, isTrendForecast);
    let startDate;
    let endDate;
    if (isTabular) {
      if (weeklyViewToggle) {
        startDate = targetStartDate;
        endDate = targetEndDate;
      } else if (granularity === GRANULARITY.DAILY) {
        startDate = targetDate?.startDate;
        endDate = targetDate?.endDate;
      } else {
        startDate = adjustedStartDate;
        endDate = adjustedEndDate;
      }
    } else if (isCumulative) {
      startDate = targetDate?.startDate;
      endDate = targetDate?.endDate;
    } else {
      startDate = weeklyViewToggle ? targetStartDate : targetDate?.startDate;
      endDate = weeklyViewToggle ? targetEndDate : targetDate?.endDate;
    }
    const targetDateFilter = getTargetDateSchema(visualFilters, startDate, endDate);
    let hotelIdFilter = [];
    if (enableHotelGroups) {
      const hotelListIds = hotelId?.hotels?.map((hotel) => hotel?.databricksId);
      hotelIdFilter = getHotelIdSchema(hotelListIds, visualFilters);
    } else {
      hotelIdFilter = getHotelIdSchema(hotelId?.databricksId, visualFilters);
    }
    const pmsSyncFilter = getPmsSyncToggleSchema(visualFilters, pmsSyncToggle);
    const symmetricComparisonFilter = getSymmetricComparisonSchema(
      visualFilters,
      symmetricComparisonToggle
    );
    const roomForecastFilter = getRoomForecastSchema(visualFilters, pmsSyncToggle);
    const revenueForecastFilter = getRevenueForecastSchema(visualFilters, pmsSyncToggle);
    const isCustomDateComparisonFilter = getCustomDateComparisonSchema(
      visualFilters,
      dateComparison,
      startDateComparison,
      endDateComparison
    );
    const selectedBreakdown = getSelectedBreakdown(visualFilters, chartBreakdown, isDetailed);
    const deAggregateSegmentAndFocusOnFilter = getTimeSegmentBreakdownAndFocusOnSchema(
      visualFilters,
      chartBreakdown,
      isDetailed,
      focusOn
    );
    const segmentFocusOnFilter = getSegmentFocusOnSchema(
      selectedBreakdown,
      focusOn?.map((item) => item?.label)
    );
    const granularityFilter = getGranularitySchema(visualFilters, granularity);
    const hotelGroupOrderFilter = getHotelGroupOrderSchema(visualFilters, isDemoUser);
    const movingAverageFilter = getMovingAverageSchema(visualFilters, movingAverage);
    //
    if (
      bookingDateFilter &&
      alignmentAndDateComparisonFilter &&
      targetDateFilter &&
      hotelIdFilter &&
      roomForecastFilter &&
      revenueForecastFilter &&
      symmetricComparisonFilter &&
      hotelGroupOrderFilter
    ) {
      let filters = [
        ...pmsSyncFilter,
        bookingDateFilter,
        ...alignmentAndDateComparisonFilter,
        targetDateFilter,
        hotelIdFilter,
        symmetricComparisonFilter,
        roomForecastFilter,
        revenueForecastFilter,
        ...isCustomDateComparisonFilter,
      ];
      if (!isPercentageChange) {
        filters = [...filters, ...deAggregateSegmentAndFocusOnFilter];
      }
      if (isMovingAverage) {
        filters = [...filters, movingAverageFilter];
      }
      const finalFilters = focusOn?.length
        ? [...filters, segmentFocusOnFilter]
        : [...filters, isForecastFilter];

      // tabular filter list
      let tabularFilters = [
        ...pmsSyncFilter,
        bookingDateFilter,
        ...alignmentAndDateComparisonFilter,
        targetDateFilter,
        hotelIdFilter,
        symmetricComparisonFilter,
        roomForecastFilter,
        revenueForecastFilter,
        ...isCustomDateComparisonFilter,
        hotelGroupOrderFilter,
        movingAverageFilter,
      ];
      if (!isPercentageChange) {
        tabularFilters = [...tabularFilters, ...deAggregateSegmentAndFocusOnFilter];
      }
      if (!weeklyViewToggle) {
        tabularFilters = [...tabularFilters, granularityFilter];
      }
      const finalTabularFilters = focusOn?.length
        ? [...tabularFilters, segmentFocusOnFilter]
        : [...tabularFilters, isForecastFilter];
      // dispath filterlist for trend time based on active tab
      if (
        tabValue === TAB_VALUE.TREND_TIME &&
        reportType === REPORT_TYPE.TREND_TIME &&
        activeTab === REPORT_TYPE.TREND_TIME
      ) {
        if (JSON.stringify(finalFilters) !== JSON.stringify(filterList)) {
          storeVisualMapper(VISUAL_MAPPER.trendTimeFilterList);
          writeLog('V2 Trend Time Filters', filters);
          dispatch(trendActions.updateTrendTimeFilterList(finalFilters));
        }
        if (
          JSON.stringify(finalTabularFilters) !== JSON.stringify(tabularFilterList) &&
          activeTab === REPORT_TYPE.TREND_TIME
        ) {
          storeVisualMapper(VISUAL_MAPPER.trendTimeTabularFilterList);
          writeLog('V2 Trend Time Tabular Filters', finalTabularFilters);
          dispatch(trendActions.updateTrendTimeTabularFilterList(finalTabularFilters));
        }
      }
      // dispath filterlist for time segment visuals based on active tab
      if (activeTab === REPORT_TYPE.TREND_SEGMENT) {
        if (JSON.stringify(finalFilters) !== JSON.stringify(TimeSegmentFilterList)) {
          storeVisualMapper(VISUAL_MAPPER.trendTimeFilterList);
          writeLog('V2 Trend Time Segment Filters', filters);
          dispatch(trendActions.updateTrendTimeSegmentFilterList(finalFilters));
        }
        if (
          JSON.stringify(finalTabularFilters) !== JSON.stringify(SegmentTabularFilterList) &&
          activeTab === REPORT_TYPE.TREND_SEGMENT
        ) {
          storeVisualMapper(VISUAL_MAPPER.trendTimeTabularFilterList);
          writeLog('V2 Trend Time Segment Tabular Filters', finalTabularFilters);
          dispatch(trendActions.updateTrendTimeSegmentTabularFilterList(finalTabularFilters));
        }
      }
    }
  }, [
    bookingDate,
    visualFilters,
    dateComparison,
    alignment,
    targetDate,
    hotelId,
    pmsSyncToggle,
    weeklyViewToggle,
    symmetricComparisonToggle,
    targetStartDate,
    targetEndDate,
    isCumulative,
    startDateComparison,
    endDateComparison,
    selectedDateComparison,
    customDate,
    isDetailed,
    focusOn,
    enableHotelGroups,
    isTrendForecast,
    reportType,
    activeTab,
    isPercentageChange,
    granularity,
    adjustedStartDate,
    adjustedEndDate,
    isTabular,
    isTrendDeAggregate,
    isMovingAverage,
    movingAverage,
    tabValue,
  ]);
  // Triggered when targetDate value changes
  useEffect(() => {
    // Set stay dates based on granularity
    const trendGranularity = getGranularity(targetDate);
    dispatch(trendActions.setGranularity(trendGranularity));
    if (trendGranularity === GRANULARITY.WEEKLY) {
      if (isSunday(new Date(targetDate.startDate))) {
        dispatch(trendActions.setAdjustedStartDate(new Date(targetDate.startDate).toISOString()));
      } else {
        dispatch(
          trendActions.setAdjustedStartDate(
            previousSunday(new Date(targetDate.startDate)).toISOString()
          )
        );
      }
      if (isSaturday(new Date(targetDate.endDate))) {
        dispatch(trendActions.setAdjustedEndDate(new Date(targetDate.endDate).toISOString()));
      } else {
        dispatch(
          trendActions.setAdjustedEndDate(nextSaturday(new Date(targetDate.endDate)).toISOString())
        );
      }
    } else if (trendGranularity === GRANULARITY.MONTHLY) {
      if (isFirstDayOfMonth(new Date(targetDate.startDate))) {
        dispatch(trendActions.setAdjustedStartDate(new Date(targetDate.startDate).toISOString()));
      } else {
        dispatch(
          trendActions.setAdjustedStartDate(
            startOfMonth(new Date(targetDate.startDate)).toISOString()
          )
        );
      }
      if (isLastDayOfMonth(new Date(targetDate.endDate))) {
        dispatch(trendActions.setAdjustedEndDate(new Date(targetDate.endDate).toISOString()));
      } else {
        dispatch(
          trendActions.setAdjustedEndDate(endOfMonth(new Date(targetDate.endDate)).toISOString())
        );
      }
    } else if (trendGranularity === GRANULARITY.YEARLY) {
      if (isSameDay(startOfYear(new Date(targetDate.startDate)), new Date(targetDate.startDate))) {
        dispatch(trendActions.setAdjustedStartDate(new Date(targetDate.startDate).toISOString()));
      } else {
        dispatch(
          trendActions.setAdjustedStartDate(
            startOfYear(new Date(targetDate.startDate)).toISOString()
          )
        );
      }
      if (isSameDay(lastDayOfYear(new Date(targetDate.endDate)), new Date(targetDate.endDate))) {
        dispatch(trendActions.setAdjustedEndDate(new Date(targetDate.endDate).toISOString()));
      } else {
        dispatch(
          trendActions.setAdjustedEndDate(lastDayOfYear(new Date(targetDate.endDate)).toISOString())
        );
      }
    }
  }, [targetDate]);
};
//
export default useTrendTime;

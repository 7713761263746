import { format, parseISO } from 'date-fns';
import {
  ISO_YEAR_MONTH_DAY_PATTERN,
  YEAR_MONTH_DAY_PATTERN,
} from 'modules/common/constants/date-range';
import COLUMN_NAMES from 'modules/common/constants/power-bi';
import { isEmpty } from 'modules/common/helpers/object';
import { capitalizeFirst } from 'modules/common/helpers/strings';
import {
  TABULAR_VIEWS,
  breakdownList,
  commands,
  compareWindows,
  consoleCommands,
  paceVariableNames,
  timeFrameOptions,
  COLLAPSE_VISUALS,
} from 'modules/common/ui-controller';
import SHARE_URL_CONFIG from 'modules/common/constants/share-url-config';
import getKeyByValue from 'modules/common/utils/decode-filters';
import {
  ACTUAL_VALUE,
  ALIGNED,
  KEYS,
  NA,
  OPERATORS,
  OTB_VALUE,
  REPORT_TYPE,
  SYMMETRIC_COMPARISON_VALUES,
  TAGS,
  VISUAL_DATE_TYPES,
} from 'modules/dashboard/constants';
import {
  allFilter,
  dateFilter,
  formatDate,
  getSelectedBreakdown,
  nameFilter,
} from 'modules/dashboard/functions';
import { TREND_TIME_SEGMENT_FILTER, TREND_TIME_X_SEGMENT_FILTER } from '../trend-tabs/constants';

/**
 * To generate power bi filter schema for stay date
 * @param {Array} visualFilters - List of registered visual filters
 * @param {Date} startDate - Start date of stay date
 * @param {Date} endDate - End date of stay date
 * @returns
 */
export const getTargetDateSchema = (visualFilters, startDate, endDate) => {
  const targetVisualFilter = visualFilters?.find((filter) =>
    filter?.tags?.includes(TAGS.TARGET_DATE)
  );
  let targetDateFilter;
  if (targetVisualFilter) {
    targetDateFilter = dateFilter(startDate, endDate, targetVisualFilter?.tableName);
  }
  return targetDateFilter;
};
/**
 * To generate power bi filter schema for stay/arrival date
 * @param {Array} visualFilters - List of registered visual filters
 * @param {String} type - selected date type
 * @param {Date} startDate - Start date of stay/arrival date
 * @param {Date} endDate - End date of  stay/arrival date
 * @returns
 */
export const getWorkbookTargetDateSchema = (visualFilters, type, startDate, endDate) => {
  let targetVisualFilter = {};
  let targetDateFilter;
  let arrivalDateVisualFilter = {};
  switch (type) {
    case VISUAL_DATE_TYPES.STAY:
      targetVisualFilter = visualFilters?.find((filter) =>
        filter?.tags?.includes(TAGS.TARGET_DATE)
      );
      if (targetVisualFilter) {
        targetDateFilter = dateFilter(startDate, endDate, targetVisualFilter?.tableName);
      }
      break;
    case VISUAL_DATE_TYPES.ARRIVAL:
      arrivalDateVisualFilter = visualFilters?.find((filter) =>
        filter?.tags?.includes(TAGS.ARRIVAL_DATE)
      );
      if (arrivalDateVisualFilter) {
        targetDateFilter = dateFilter(
          startDate,
          endDate,
          arrivalDateVisualFilter?.tableName,
          arrivalDateVisualFilter?.columnName
        );
      }
      break;
    default:
      break;
  }
  return targetDateFilter;
};
/**
 * To generate power bi filter schema for date switch
 * @param {Array} visualFilters - List of registered visual filters
 * @param {String} type - selected date type
 * @returns
 */
export const getDateSwitchSchema = (visualFilters, type) => {
  const dateSwitchVisualFilter = visualFilters?.find((filter) =>
    filter?.tags?.includes(TAGS.DATE_SWITCH)
  );
  let dateSwitchFilter;
  if (dateSwitchVisualFilter) {
    dateSwitchFilter = nameFilter(
      dateSwitchVisualFilter?.tableName,
      dateSwitchVisualFilter?.columnName,
      type === VISUAL_DATE_TYPES.STAY ? ['Stay'] : ['Arrival']
    );
  }
  return dateSwitchFilter;
};

/**
 * To generate power bi filter schema for hotel name
 * @param {Array} hotelDropdownList - List of hotels in hotel dropdown
 * @param {Array} visualFilters -  List of registered visual filters
 * @param {String} hotel - Selected hotel details
 * @returns
 */
// Need to support hotel array : TODO
export const getHotelNameSchema = (hotelDropdownList, visualFilters, hotel) => {
  // Match the hotel with hotel name or anonymous name (eg:Hotel 1)
  const selectedHotel = hotelDropdownList?.find(
    (filter) => filter.label === hotel?.label || filter.label === hotel?.altName
  );
  const hotelLists = visualFilters?.filter((filter) => filter?.tags?.includes(TAGS.HOTEL_NAME));
  // To identify the hotel name filter when the user is a demo user/ regular user
  const hotelNameVisualFilter = hotelLists?.find((filter) =>
    filter?.values?.some(
      (element) => [selectedHotel?.label, selectedHotel?.altName].indexOf(element) >= 0
    )
  );
  let hotelNameFilter;
  if (hotelNameVisualFilter) {
    hotelNameFilter = nameFilter(
      hotelNameVisualFilter?.tableName,
      hotelNameVisualFilter?.columnName,
      hotelNameVisualFilter?.values
    );
  }
  return hotelNameFilter;
};

/**
 * To generate power bi filter schema for hotel id
 * @param {Array} visualFilters -  List of registered visual filters
 * @param {String} hotelId - Selected hotel id
 * @returns
 */
export const getHotelIdSchema = (hotelId, visualFilters) => {
  const hotelVisualFilter = visualFilters?.find((filter) => filter?.tags?.includes(TAGS.HOTEL_ID));
  let hotelIdFilter;
  if (hotelVisualFilter) {
    hotelIdFilter = nameFilter(
      hotelVisualFilter?.tableName,
      hotelVisualFilter?.columnName,
      Array.isArray(hotelId) ? hotelId : [hotelId]
    );
  }
  return hotelIdFilter;
};

/**
 * To generate power bi filter schema for booking date
 * @param {Array} visualFilters - List of registered visual filters
 * @param {Date} startDate - Start date of booking date
 * @param {Date} endDate - End date of booking date
 * @returns
 */
export const getBookingDateSchema = (visualFilters, startDate, endDate) => {
  const bookingVisual = visualFilters?.filter((filter) =>
    filter?.tags?.includes(TAGS.BOOKING_DATE)
  );
  let bookingDateFilter;
  if (bookingVisual?.length) {
    bookingDateFilter = dateFilter(startDate, endDate, bookingVisual[0]?.tableName);
  }
  return bookingDateFilter;
};

/**
 * Generate power bi filter schema for alignment and date comparison filters
 * @param {Array} visualFilters - List of registered visual filters
 * @param {String} alignmentId - selected alignment id
 * @param {String} dateComparison - selected date comparison value
 * @returns
 */
export const getAlignmentAndDateComparisonSchema = (visualFilters, alignmentId, dateComparison) => {
  const alignmentVisual = visualFilters?.filter((filter) => filter?.id?.includes(alignmentId));
  let alignmentFilter;
  let dateComparisonFilter;
  if (alignmentVisual?.length) {
    alignmentFilter = nameFilter(alignmentVisual[0]?.tableName, alignmentVisual[0]?.columnName, [
      NA,
      capitalizeFirst(alignmentVisual[0]?.type),
    ]);
    const columnName = alignmentVisual[0]?.values?.filter((filter) =>
      filter.value.includes(dateComparison)
    )[0]?.columnName;
    dateComparisonFilter = nameFilter(alignmentVisual[0]?.tableName, columnName, [dateComparison]);
  }
  return [alignmentFilter, dateComparisonFilter];
};
/**
 * Generate Power BI filter schema for alignment and date comparison filters
 * @param {Array} visualFilters - List of registered visual filters
 * @param {String} alignmentId - Selected alignment id
 * @param {String} indexValue - Selected index value
 * @returns {Object} - Power BI filter schema
 */
export const getAlignmentAndDateComparisonIndexSchema = (
  visualFilters,
  alignmentId,
  indexValue
) => {
  // Filter the visual filters based on alignmentId
  const alignmentVisual = visualFilters?.filter((filter) => filter?.id?.includes(alignmentId));

  // Initialize alignmentFilter
  let alignmentFilter;

  // If there are alignmentVisuals present
  if (alignmentVisual?.length) {
    alignmentFilter = nameFilter(alignmentVisual[0]?.tableName, 'Index', [indexValue]);
  }

  // Return the alignment filter
  return alignmentFilter;
};

/**
 * Generate power bi filter schema for symmetric comparison filters
 * @param {Array} visualFilters - List of registered visual filters
 * @param {Boolean} symmetricComparisonToggle - Toggle state
 * @returns
 */
export const getSymmetricComparisonSchema = (visualFilters, symmetricComparisonToggle) => {
  const symmetricComparison = visualFilters?.filter((filter) =>
    filter?.tags?.includes(TAGS.SYMMETRIC_COMPARISON)
  );
  let symmetricComparisonFilter;
  if (symmetricComparison?.length) {
    const filterValue = symmetricComparisonToggle
      ? [SYMMETRIC_COMPARISON_VALUES.SYMMETRIC]
      : [SYMMETRIC_COMPARISON_VALUES.OTB];
    symmetricComparisonFilter = nameFilter(
      symmetricComparison[0]?.tableName,
      symmetricComparison[0]?.columnName,
      filterValue
    );
  }
  return symmetricComparisonFilter;
};

/**
 * Generate power bi filter schema for custom date comparison filters
 * @param {Array} visualFilters - List of registered visual filters
 * @param {String} dateComparison - selected date comparison value
 * @param {Date} startDate - Start date of custom comparison date
 * @param {Date} endDate - End date of  custom comparison date
 * @returns
 */
export const getCustomDateComparisonSchema = (
  visualFilters,
  dateComparison,
  startDate,
  endDate
) => {
  const customDateComparison = visualFilters?.filter((filter) =>
    filter?.tags?.includes(TAGS.CUSTOM_DATE_COMPARISON)
  );
  let isCustomDateComparisonFilter = [];
  // Generate if 'custom' is selected for date comparison and when end date is not empty
  if (
    customDateComparison?.length &&
    dateComparison &&
    dateComparison === KEYS.CUSTOM &&
    endDate !== null
  ) {
    isCustomDateComparisonFilter = [
      dateFilter(
        startDate,
        endDate,
        customDateComparison[0]?.tableName,
        customDateComparison[0]?.columnName
      ),
    ];
  }
  return isCustomDateComparisonFilter;
};

/**
 * Generate power bi filter schema for breakdown filters
 * @param {Array} visualFilters - List of registered visual filters
 * @param {String} chartBreakdown - Selected chart breakdown id
 * @param {Boolean} isDetailed - chart breakdown details toggle state
 * @returns
 */
export const getChartBreakdownFilterList = (visualFilters, chartBreakdown, isDetailed) => {
  const selectedBreakdown = getSelectedBreakdown(visualFilters, chartBreakdown, isDetailed);
  const summaryChartVisual = visualFilters?.find((filter) =>
    filter?.tags?.includes(TAGS.SUMMARY_SEGMENT_BREAKDOWN)
  );
  let chartBreakdownFilter;
  if (summaryChartVisual && selectedBreakdown) {
    chartBreakdownFilter = nameFilter(
      summaryChartVisual?.tableName,
      summaryChartVisual?.columnName,
      [selectedBreakdown]
    );
  }
  return chartBreakdownFilter;
};

/**
 * Generate  power bi filter schema for pms sync filter
 * @param {Array} visualFilters - List of registered visual filters
 * @param {Boolean} pmsSyncToggle - Pms sync toggle state
 * @param {String} tag - tag
 * @returns
 */
export const getPmsSyncSchema = (visualFilters, pmsSyncToggle, tag) => {
  let pmsSyncFilter = [];
  const pmsSyncVisual = visualFilters?.filter((filter) =>
    filter?.tags?.includes(TAGS.PMS_SYNC && tag)
  );
  // generate filter when pms toggle is off
  if (!pmsSyncToggle) {
    pmsSyncFilter = pmsSyncVisual?.map((item) =>
      nameFilter(item?.tableName, item?.columnName, item?.values, OPERATORS.NOT_IN)
    );
  } else {
    pmsSyncFilter = pmsSyncVisual?.map((item) => allFilter(item?.tableName, item?.columnName));
  }
  return pmsSyncFilter;
};

/**
 * Generate  power bi filter schema for pms sync filter
 * @param {Boolean} pmsSyncToggle - Pms sync toggle state
 * @returns
 */
export const getDashboardPmsSyncSchema = (pmsSyncToggle) => {
  let pmsSyncFilter = [];
  // generate filter when pms toggle is off
  if (pmsSyncToggle) {
    pmsSyncFilter = [allFilter('pms_switch', 'Switch')];
  } else {
    pmsSyncFilter = [
      nameFilter('pms_switch', 'Switch', ['Off'], OPERATORS.IN),
      nameFilter('Dim_Channel', 'channel', ['PMS'], OPERATORS.NOT_IN),
    ];
  }

  return pmsSyncFilter;
};

/**
 * Generate  power bi filter schema for pace horizon filter
 * @param {Array} visualFilters - List of registered visual filters
 * @param {String} paceHorizon -Selected pace horizon id
 * @returns
 */
export const getPaceHorizonSchema = (visualFilters, paceHorizon) => {
  const paceHorizonVisual = visualFilters?.filter((filter) => filter?.id === paceHorizon);
  let paceHorizonFilter;
  if (paceHorizonVisual?.length) {
    paceHorizonFilter = nameFilter(
      paceHorizonVisual[0]?.tableName,
      paceHorizonVisual[0]?.columnName,
      paceHorizonVisual[0]?.values
    );
  }
  return paceHorizonFilter;
};

/**
 * Generate  power bi filter schema for pace horizon filter
 * @param {Array} visualFilters - List of registered visual filters
 * @param {String} paceHorizon -Selected pace horizon id
 * @param {Boolean} isPaceHorizonSelected - state of pace horizon selected
 * @returns
 */
export const getPaceWidgetPaceHorizonSchema = (
  visualFilters,
  paceHorizon,
  isPaceHorizonSelected
) => {
  const paceHorizonVisual = visualFilters?.filter((filter) => filter?.id === paceHorizon);
  let paceHorizonFilter;
  if (isPaceHorizonSelected) {
    if (paceHorizonVisual?.length) {
      paceHorizonFilter = nameFilter(
        paceHorizonVisual[0]?.tableName,
        paceHorizonVisual[0]?.columnName,
        paceHorizonVisual[0]?.values
      );
    }
  } else {
    paceHorizonFilter = nameFilter(
      paceHorizonVisual[0]?.tableName,
      paceHorizonVisual[0]?.columnName,
      ['All']
    );
  }

  return paceHorizonFilter;
};

/**
 * Generate  power bi filter schema for pace variable filter
 * @param {Array} visualFilters - List of registered visual filters
 * @param {String} paceVariable - Selected pace variable id
 * @param {String} tag - tag
 * @returns
 */
export const getPaceVariableSchema = (visualFilters, paceVariable, tag) => {
  const paceVariableVisual = visualFilters?.filter(
    (filter) => filter?.values?.[0] === paceVariable && filter?.tags?.[0] === tag
  );
  let paceVariableFilter;
  if (paceVariableVisual?.length) {
    paceVariableFilter = nameFilter(
      paceVariableVisual[0]?.tableName,
      paceVariableVisual[0]?.columnName,
      paceVariableVisual[0]?.values
    );
  }
  return paceVariableFilter;
};

/**
 * Generate  power bi filter schema for room forecast filter
 * @param {Array} visualFilters - List of registered visual filters
 * @param {Boolean} pmsSyncToggle - Pms sync toggle state
 * @returns
 */
export const getRoomForecastSchema = (visualFilters, pmsSyncToggle) => {
  let roomForecastFilter;
  const roomForecast = visualFilters?.filter((filter) =>
    filter?.tags?.includes(TAGS.ROOM_FORECAST)
  );
  if (roomForecast?.length) {
    roomForecastFilter = nameFilter(roomForecast[0]?.tableName, roomForecast[0]?.columnName, [
      !pmsSyncToggle,
    ]);
  }
  return roomForecastFilter;
};

/**
 * Generate  power bi filter schema for revenue forecast filter
 * @param {Array} visualFilters - List of registered visual filters
 * @param {Boolean} pmsSyncToggle - Pms sync toggle state
 * @returns
 */
export const getRevenueForecastSchema = (visualFilters, pmsSyncToggle) => {
  let revenueForecastFilter;
  const revenueForecast = visualFilters?.filter((filter) =>
    filter?.tags?.includes(TAGS.REVENUE_FORECAST)
  );
  if (revenueForecast?.length) {
    revenueForecastFilter = nameFilter(
      revenueForecast[0]?.tableName,
      revenueForecast[0]?.columnName,
      [!pmsSyncToggle]
    );
  }
  return revenueForecastFilter;
};
/**
 * Generate  power bi filter schema for to show or hide forecast filter
 * @param {Array} visualFilters - List of registered visual filters
 * @param {Boolean} isForecast - Forecasts toggle state
 * @returns
 */
export const getIsForecastSchema = (visualFilters, isForecast) => {
  let isForecastFilter;
  const forecastFilter = visualFilters?.find((filter) => filter?.tags?.includes(TAGS.IS_FORECAST));
  if (forecastFilter) {
    isForecastFilter = nameFilter(forecastFilter?.tableName, forecastFilter?.columnName, [
      isForecast ? 'On' : 'Off',
    ]);
  }
  return isForecastFilter;
};
/**
 * Generate  power bi filter schema for pms sync forecast filter
 * @param {Array} visualFilters - List of registered visual filters
 * @param {Boolean} pmsSyncToggle - Pms sync toggle state
 * @returns
 */
export const getPmsForecastSchema = (visualFilters, pmsSyncToggle) => {
  let pmsSyncForecastFilter;
  // const pmsForecast = visualFilters?.find((filter) =>
  //   filter?.tags?.includes(TAGS.PMS_SYNC_FORECAST)
  // );
  const pmsForecast = visualFilters?.filter((filter) =>
    filter?.tags?.includes(TAGS.PMS_SYNC_FORECAST)
  );
  if (pmsForecast?.length) {
    pmsSyncForecastFilter = pmsForecast?.map((item) =>
      nameFilter(item?.tableName, item?.columnName, [!pmsSyncToggle])
    );

    // pmsSyncForecastFilter = nameFilter(pmsForecast?.tableName, pmsForecast?.columnName, [
    //   !pmsSyncToggle,
    // ]);
  }
  return pmsSyncForecastFilter;
};
/**
 *
 * @param {*} visualFilters
 * @param {*} percentage
 * @returns
 */
export const percentageSchema = (visualFilters, percentage) => {
  let percentageValueFilter;
  const selectedPercentageValue = visualFilters?.find((filter) => filter.id === percentage);
  if (selectedPercentageValue) {
    percentageValueFilter = nameFilter(
      selectedPercentageValue?.tableName,
      selectedPercentageValue?.columnName,
      [parseInt(selectedPercentageValue?.values[0], 10)]
    );
  }
  return percentageValueFilter;
};
/**
 * Get granularity filter schema
 * @param {Array} visualFilters - List of registered visual filters
 * @returns
 */
export const getGranularitySchema = (visualFilters, granularity) => {
  let granularityFilter;
  const filterDetails = visualFilters?.find((filter) => filter?.tags?.includes(TAGS.GRANULARITY));
  if (filterDetails) {
    granularityFilter = nameFilter(filterDetails?.tableName, filterDetails?.columnName, [
      granularity,
    ]);
  }
  return granularityFilter;
};

/**
 * Get pace granularity filter schema
 * @param {Array} visualFilters - List of registered visual filters
 * @returns
 */
export const getPaceGranularitySchema = (visualFilters, granularity) => {
  let granularityFilter;
  const filterDetails = visualFilters?.find((filter) =>
    filter?.tags?.includes(TAGS.GRANULARITY_BD)
  );
  if (filterDetails) {
    granularityFilter = nameFilter(filterDetails?.tableName, filterDetails?.columnName, [
      granularity,
    ]);
  }
  return granularityFilter;
};

/**
 * Generate  power bi filter schema for far field pms sync filter
 * @param {Array} visualFilters - List of registered visual filters
 * @param {Boolean} pmsSyncToggle - Pms sync toggle state
 * @returns
 */
export const getFarFieldPmsSyncSchema = (visualFilters, pmsSyncToggle) => {
  let pmsSyncFilter = [];

  const pmsSyncVisual = visualFilters?.filter((filter) =>
    filter?.tags?.includes(TAGS.FAR_FIELD_PMS_SYNC)
  );
  pmsSyncFilter = pmsSyncVisual?.map((item) =>
    nameFilter(item?.tableName, item?.columnName, [!pmsSyncToggle])
  );

  return pmsSyncFilter;
};
/**
 * Get moving average filter list
 * @param {Array} visualFilters - List of registered visual filters
 * @returns
 */
export const getMovingAverageFilter = (visualFilters) =>
  visualFilters
    ?.filter((filter) => filter?.tags?.includes(TAGS.MOVING_AVERAGE))
    ?.map((data) => ({
      label: Number(data?.values),
      id: data?.id,
    }))
    ?.sort((a, b) => a.label - b.label);

/**
 * Generate summary share url with selected filter schema
 * @param {Boolean} pmsSyncToggle - pms sync toggle state
 * @param {Object} hotelId - selected hotel
 * @param {Object} targetDate - stay date range
 * @param {String} dateComparison - date comparison value
 * @param {String } paceVariable  - pace variable value
 * @param {Array} visualFilters - List of registered visual filters
 * @param {String} paceHorizon - pace horizon value
 * @param {Date} startDate  - start date of custom option
 * @param {Date} endDate - end date of custom option
 * @param {String} customDate - type of custom date option
 * @param {Boolean} isDetailed - isDetailed state
 * @param {String} chartBreakdown - breakdown value
 * @param {Boolean} customBooking - is custom booking option enabled state
 * @param {Object} bookingDate - booking date range
 * @param {Boolean} alignmentToggle - alignment toggle state
 * @param {Boolean} symmetricComparisonToggle - symmetric alignment toggle state
 * @param {String} localCurrency - selected currency state
 */
export const generateSummaryShareUrl = (
  pmsSyncToggle,
  hotelId,
  targetDate,
  dateComparison,
  paceVariable,
  visualFilters,
  paceHorizon,
  startDate,
  endDate,
  customDate,
  isDetailed,
  chartBreakdown,
  customBooking,
  bookingDate,
  alignmentToggle,
  symmetricComparisonToggle,
  hotelGroupId,
  enableHotelGroups,
  visualToggle,
  paceVisualIsDisplay,
  revenueSetIsDisplay,
  cxlSetIsDisplay,
  symmetricComparisonIsDisplay,
  forecastToggle,
  enableSecondaryHotelGroups,
  hotelFocusOn,
  // hotelGroupFocusOn,
  normalizedViewToggle,
  localCurrency
) => {
  let chartFilterCommand;
  let paceHorizonCommand;
  const chartVisual = visualFilters?.find((filter) => filter?.id?.includes(chartBreakdown));
  if (chartVisual) {
    chartFilterCommand = `${consoleCommands.SEGMENT}=${getKeyByValue(
      breakdownList,
      chartVisual?.type
    )}${Number(isDetailed)}`;
  }
  let bookingDateCommand = `${consoleCommands.BOOKING_DATE}=`;
  if (customBooking) {
    bookingDateCommand = bookingDateCommand.concat(
      `${format(parseISO(bookingDate?.startDate), ISO_YEAR_MONTH_DAY_PATTERN)}-${format(
        parseISO(bookingDate?.endDate),
        ISO_YEAR_MONTH_DAY_PATTERN
      )}`
    );
  } else {
    bookingDateCommand = bookingDateCommand.concat(commands.OTB);
  }
  const alignmentCommand = `${consoleCommands.ALIGNMENT}=${Number(alignmentToggle)}`;
  const symmetricComparisonCommand = `${consoleCommands.SYMMETRIC}=${Number(
    symmetricComparisonToggle
  )}`;
  const pmsSyncCommand = `${consoleCommands.PMS}=${Number(pmsSyncToggle)}`;
  let hotelCommand;
  if (!enableHotelGroups) {
    hotelCommand = `${consoleCommands.HOTEL}=${hotelId?.id}`;
  } else {
    hotelCommand = `${consoleCommands.HOTEL_GROUP}=${hotelGroupId?.id
      ?.trim()
      ?.replaceAll(SHARE_URL_CONFIG.SPACE, SHARE_URL_CONFIG.ENCODED_SPACE)}`;
  }

  const stayDateCommand = `${consoleCommands.STAY_DATE}=${format(
    parseISO(targetDate?.startDate),
    ISO_YEAR_MONTH_DAY_PATTERN
  )}-${format(parseISO(targetDate?.endDate), ISO_YEAR_MONTH_DAY_PATTERN)}`;
  const compareWindowCommand = `${consoleCommands.COMPARE_WINDOW}=${getKeyByValue(
    compareWindows,
    dateComparison
  )}`;
  const paceVariableCommand = `${consoleCommands.PACE_VARIABLE}=${getKeyByValue(
    paceVariableNames,
    paceVariable
  )}`;
  const paceHorizonVisual = visualFilters?.filter((filter) => filter?.id === paceHorizon);
  if (paceHorizonVisual) {
    const dd = paceHorizonVisual[0]?.values[0]?.replace(' days', '');
    paceHorizonCommand = `${consoleCommands.PACE_HORIZON}=${dd}`;
  }
  let customDateCommand;
  if (startDate && endDate && customDate && dateComparison === KEYS.CUSTOM) {
    customDateCommand = `${consoleCommands.CUSTOM_DATES}=${customDate}-${
      customDate === KEYS.START_DATE
        ? format(parseISO(startDate), ISO_YEAR_MONTH_DAY_PATTERN)
        : format(parseISO(endDate), ISO_YEAR_MONTH_DAY_PATTERN)
    }`;
  }
  const extendingForecastToggleCommand = `${consoleCommands.EXTENDING_FORECAST}=${Number(
    visualToggle
  )}`;
  const symmetricComparisonIsDisplayCommand = `${
    consoleCommands.SYMMETRIC_COMPARISON_COLLAPSE
  }=${Number(symmetricComparisonIsDisplay)}`;
  const visualLoadingCommand = `${consoleCommands.VISUAL_LOADING}=${COLLAPSE_VISUALS.PACE}${Number(
    paceVisualIsDisplay
  )},${COLLAPSE_VISUALS.REVENUE}${Number(revenueSetIsDisplay)},${COLLAPSE_VISUALS.CX}${Number(
    cxlSetIsDisplay
  )}`;
  const forecastToggleCommand = `${consoleCommands.FORECAST}=${Number(forecastToggle)}`;
  //
  let hotelComparisonCommand;
  if (!enableSecondaryHotelGroups) {
    hotelComparisonCommand = `${consoleCommands.COMPARE_HOTEL}=${hotelFocusOn?.id}`;
  } else {
    hotelComparisonCommand = `${consoleCommands.COMPARE_HOTEL_GROUP}=${hotelFocusOn?.id
      ?.trim()
      ?.replaceAll(SHARE_URL_CONFIG.SPACE, SHARE_URL_CONFIG.ENCODED_SPACE)}`;
  }
  const normalizedViewCommand = `${consoleCommands.NORMALIZED_VIEW}=${Number(
    normalizedViewToggle
  )}`;
  const localCurrencyCommand = `${consoleCommands.CURRENCY}=${localCurrency}`;
  //
  const summaryUrl = [
    chartFilterCommand,
    bookingDateCommand,
    alignmentCommand,
    symmetricComparisonCommand,
    pmsSyncCommand,
    hotelCommand,
    stayDateCommand,
    compareWindowCommand,
    paceVariableCommand,
    paceHorizonCommand,
    customDateCommand,
    extendingForecastToggleCommand,
    symmetricComparisonIsDisplayCommand,
    visualLoadingCommand,
    forecastToggleCommand,
    hotelComparisonCommand,
    normalizedViewCommand,
    localCurrencyCommand,
  ]
    ?.filter(Boolean)
    .join('&')
    ?.replaceAll(/\s*|\t|\r|\n/gm, '');
  //
  return summaryUrl;
};
/**
 *  Generate tabular share url with selected filter schema
 * @param {*} pmsSyncToggle
 * @param {*} hotelId
 * @param {*} targetDate
 * @param {*} dateComparison
 * @param {*} visualFilters
 * @param {*} startDateComparison
 * @param {*} endDateComparison
 * @param {*} customDate
 * @param {*} isDetailed
 * @param {*} chartBreakdown
 * @param {*} vOTB
 * @param {*} bookingDate
 * @param {*} alignmentToggle
 * @param {*} symmetricComparisonToggle
 * @param {*} hotelGroupId
 * @param {*} enableHotelGroups
 * @param {*} revenueIsDisplayTT
 * @param {*} compareWindowCollapseToggle
 * @param {*} focusOn
 * @param {*} localCurrency
 * @returns
 */
export const generateTabularShareUrl = (
  pmsSyncToggle,
  hotelId,
  targetDate,
  dateComparison,
  visualFilters,
  startDateComparison,
  endDateComparison,
  customDate,
  isDetailed,
  chartBreakdown,
  vOTB,
  bookingDate,
  alignmentToggle,
  symmetricComparisonToggle,
  hotelGroupId,
  enableHotelGroups,
  revenueIsDisplayTT,
  compareWindowCollapseToggle,
  focusOn,
  localCurrency
) => {
  let chartFilterCommand;
  let focusOnCommand;
  const chartVisual = visualFilters?.find((filter) => filter?.id?.includes(chartBreakdown));
  if (chartVisual) {
    chartFilterCommand = `${consoleCommands.SEGMENT}=${getKeyByValue(
      breakdownList,
      chartVisual?.type
    )}${Number(isDetailed)}`;
  }
  const focusOnValues = focusOn?.map((item) => item?.label);
  if (focusOnValues?.length > 0) {
    focusOnCommand = `${consoleCommands.FOCUS}=${focusOnValues
      ?.map((focus) => focus.replace(/ +/g, ''))
      ?.filter(Boolean)
      ?.join(',')}`;
  }
  let bookingDateCommand = `${consoleCommands.BOOKING_DATE}=`;
  if (Number(vOTB) !== 0) {
    bookingDateCommand = bookingDateCommand.concat(
      `${format(parseISO(bookingDate?.startDate), ISO_YEAR_MONTH_DAY_PATTERN)}-${format(
        parseISO(bookingDate?.endDate),
        ISO_YEAR_MONTH_DAY_PATTERN
      )}`
    );
  } else {
    bookingDateCommand = bookingDateCommand.concat(commands.OTB);
  }
  const alignmentCommand = `${consoleCommands.ALIGNMENT}=${Number(alignmentToggle)}`;
  const symmetricComparisonCommand = `${consoleCommands.SYMMETRIC}=${Number(
    symmetricComparisonToggle
  )}`;
  const pmsSyncCommand = `${consoleCommands.PMS}=${Number(pmsSyncToggle)}`;
  let hotelCommand;
  if (!enableHotelGroups) {
    hotelCommand = `${consoleCommands.HOTEL}=${hotelId?.id}`;
  } else {
    hotelCommand = `${consoleCommands.HOTEL_GROUP}=${hotelGroupId?.id
      ?.trim()
      ?.replaceAll(SHARE_URL_CONFIG.SPACE, SHARE_URL_CONFIG.ENCODED_SPACE)}`;
  }

  const stayDateCommand = `${consoleCommands.STAY_DATE}=${format(
    parseISO(targetDate?.startDate),
    ISO_YEAR_MONTH_DAY_PATTERN
  )}-${format(parseISO(targetDate?.endDate), ISO_YEAR_MONTH_DAY_PATTERN)}`;
  const compareWindowCommand = `${consoleCommands.COMPARE_WINDOW}=${getKeyByValue(
    compareWindows,
    dateComparison
  )}`;
  let customDateCommand;
  if (startDateComparison && endDateComparison && customDate && dateComparison === KEYS.CUSTOM) {
    customDateCommand = `${consoleCommands.CUSTOM_DATES}=${customDate}-${
      customDate === KEYS.START_DATE
        ? format(parseISO(startDateComparison), ISO_YEAR_MONTH_DAY_PATTERN)
        : format(parseISO(endDateComparison), ISO_YEAR_MONTH_DAY_PATTERN)
    }`;
  }

  const symmetricComparisonIsDisplayCommand = `${
    consoleCommands.SYMMETRIC_COMPARISON_COLLAPSE
  }=${Number(compareWindowCollapseToggle)}`;
  const visualLoadingCommand = `${consoleCommands.VISUAL_LOADING}=${
    COLLAPSE_VISUALS.TABULAR
  }${Number(revenueIsDisplayTT)}`;
  const localCurrencyCommand = `${consoleCommands.CURRENCY}=${localCurrency}`;
  //
  const tabularUrl = [
    chartFilterCommand,
    bookingDateCommand,
    alignmentCommand,
    symmetricComparisonCommand,
    pmsSyncCommand,
    hotelCommand,
    stayDateCommand,
    compareWindowCommand,
    customDateCommand,
    focusOnCommand,
    symmetricComparisonIsDisplayCommand,
    visualLoadingCommand,
    localCurrencyCommand,
  ]
    ?.filter(Boolean)
    .join('&')
    ?.replaceAll(/\s*|\t|\r|\n/gm, '');
  //
  return tabularUrl;
};
/**
 * Generate workbook share url with selected filter schema
 * @param {Boolean} pmsSyncToggle - pms sync toggle state
 * @param {Object} hotelId - selected hotel
 * @param {Object} targetDate - stay date range
 * @param {String} dateComparison - date comparison value
 * @param {Date} startDate  - start date of custom option
 * @param {Date} endDate - end date of custom option
 * @param {String} customDate - type of custom date option
 * @param {Boolean} customBooking - is custom booking option enabled state
 * @param {Object} bookingDate - booking date range
 * @param {Boolean} alignmentToggle - alignment toggle state
 * @param {Boolean} symmetricComparisonToggle - symmetric alignment toggle state
 * @param {String} localCurrency - selected currency state
 */
export const generateWorkbookShareUrl = (
  pmsSyncToggle,
  hotelId,
  targetDate,
  dateComparison,
  startDate,
  endDate,
  customDate,
  customBooking,
  bookingDate,
  alignmentToggle,
  symmetricComparisonToggle,
  enableHotelGroups,
  symmetricComparisonIsDisplay,
  d1SetIsDisplay,
  d2SetIsDisplay,
  d3SetIsDisplay,
  enableSecondaryHotelGroups,
  hotelFocusOn,
  normalizedViewToggle,
  localCurrency,
  selectedDateType,
  isRatePlan
) => {
  let bookingDateCommand = `${consoleCommands.BOOKING_DATE}=`;
  if (customBooking) {
    bookingDateCommand = bookingDateCommand.concat(
      `${format(parseISO(bookingDate?.startDate), ISO_YEAR_MONTH_DAY_PATTERN)}-${format(
        parseISO(bookingDate?.endDate),
        ISO_YEAR_MONTH_DAY_PATTERN
      )}`
    );
  } else {
    bookingDateCommand = bookingDateCommand.concat(commands.OTB);
  }
  const alignmentCommand = `${consoleCommands.ALIGNMENT}=${Number(alignmentToggle)}`;
  const symmetricComparisonCommand = `${consoleCommands.SYMMETRIC}=${Number(
    symmetricComparisonToggle
  )}`;
  const pmsSyncCommand = `${consoleCommands.PMS}=${Number(pmsSyncToggle)}`;
  let hotelCommand;
  if (!enableHotelGroups) {
    hotelCommand = `${consoleCommands.HOTEL}=${hotelId?.id}`;
  } else {
    hotelCommand = `${consoleCommands.HOTEL_GROUP}=${hotelId?.id
      ?.trim()
      ?.replaceAll(SHARE_URL_CONFIG.SPACE, SHARE_URL_CONFIG.ENCODED_SPACE)}`;
  }
  //
  const stayDateCommand = `${consoleCommands.STAY_DATE}=${format(
    parseISO(targetDate?.startDate),
    ISO_YEAR_MONTH_DAY_PATTERN
  )}-${format(parseISO(targetDate?.endDate), ISO_YEAR_MONTH_DAY_PATTERN)}`;
  const compareWindowCommand = `${consoleCommands.COMPARE_WINDOW}=${getKeyByValue(
    compareWindows,
    dateComparison
  )}`;
  //
  let customDateCommand;
  if (startDate && endDate && customDate && dateComparison === KEYS.CUSTOM) {
    customDateCommand = `${consoleCommands.CUSTOM_DATES}=${customDate}-${
      customDate === KEYS.START_DATE
        ? format(parseISO(startDate), ISO_YEAR_MONTH_DAY_PATTERN)
        : format(parseISO(endDate), ISO_YEAR_MONTH_DAY_PATTERN)
    }`;
  }
  const symmetricComparisonIsDisplayCommand = `${
    consoleCommands.SYMMETRIC_COMPARISON_COLLAPSE
  }=${Number(symmetricComparisonIsDisplay)}`;
  //
  const visualLoadingCommand = `${consoleCommands.VISUAL_LOADING}=${COLLAPSE_VISUALS.D1}${Number(
    d1SetIsDisplay
  )},${COLLAPSE_VISUALS.D2}${Number(d2SetIsDisplay)},${COLLAPSE_VISUALS.D3}${Number(
    d3SetIsDisplay
  )}`;
  let hotelComparisonCommand;
  if (!enableSecondaryHotelGroups) {
    hotelComparisonCommand = `${consoleCommands.COMPARE_HOTEL}=${hotelFocusOn?.id}`;
  } else {
    hotelComparisonCommand = `${consoleCommands.COMPARE_HOTEL_GROUP}=${hotelFocusOn?.id
      ?.trim()
      ?.replaceAll(SHARE_URL_CONFIG.SPACE, SHARE_URL_CONFIG.ENCODED_SPACE)}`;
  }
  const normalizedViewCommand = `${consoleCommands.NORMALIZED_VIEW}=${Number(
    normalizedViewToggle
  )}`;
  const localCurrencyCommand = `${consoleCommands.CURRENCY}=${localCurrency}`;
  //
  const dateTypeCommand = `${consoleCommands.DATE_TYPE}=${selectedDateType.id}`;
  //
  const ratePlanViewCommand = `${consoleCommands.RATE_PLAN}=${Number(isRatePlan)}`;
  //
  const workbookUrl = [
    bookingDateCommand,
    alignmentCommand,
    symmetricComparisonCommand,
    pmsSyncCommand,
    hotelCommand,
    stayDateCommand,
    compareWindowCommand,
    customDateCommand,
    symmetricComparisonIsDisplayCommand,
    visualLoadingCommand,
    normalizedViewCommand,
    hotelComparisonCommand,
    dateTypeCommand,
    localCurrencyCommand,
    ratePlanViewCommand,
  ]
    ?.filter(Boolean)
    .join('&')
    ?.replaceAll(/\s*|\t|\r|\n/gm, '');
  //
  return workbookUrl;
};
/**
 * Generate pace time share url with selected filter schema
 * @param {Boolean} pmsSyncToggle - pms sync toggle state
 * @param {Object} hotelId - selected hotel
 * @param {Object} targetDate - stay date range
 * @param {String} paceVariable  - pace variable value
 * @param {Array} visualFilters - List of registered visual filters
 * @param {String} paceHorizon - pace horizon value
 * @param {Boolean} lagAligned - lag aligned toggle state
 * @param {Boolean} isCumulative - cumulative toggle state
 * @param {Array} paceForecast - list of pace forecast values
 * @param {Boolean} alignmentToggle - alignment toggle state
 * @param {Boolean} symmetricComparisonToggle - symmetric alignment toggle state
 * @param {String} dateComparison - date comparison value
 * @param {String} percentage - percentage value
 * @returns
 */
export const generatePaceTimeShareUrl = (
  pmsSyncToggle,
  hotelId,
  targetDate,
  paceVariable,
  visualFilters,
  paceHorizon,
  lagAligned,
  isCumulative,
  paceForecast,
  alignmentToggle,
  symmetricComparisonToggle,
  dateComparison,
  percentage,
  enableHotelGroups,
  hotelGroupId
) => {
  let paceHorizonCommand;
  let percentageCommand;
  //
  const pmsSyncCommand = `${consoleCommands.PMS}=${Number(pmsSyncToggle)}`;
  let hotelCommand;
  if (!enableHotelGroups) {
    hotelCommand = `${consoleCommands.HOTEL}=${hotelId?.id}`;
  } else {
    hotelCommand = `${consoleCommands.HOTEL_GROUP}=${hotelGroupId?.id
      ?.trim()
      ?.replaceAll(SHARE_URL_CONFIG.SPACE, SHARE_URL_CONFIG.ENCODED_SPACE)}`;
  }
  const stayDateCommand = `${consoleCommands.STAY_DATE}=${format(
    parseISO(targetDate?.startDate),
    ISO_YEAR_MONTH_DAY_PATTERN
  )}-${format(parseISO(targetDate?.endDate), ISO_YEAR_MONTH_DAY_PATTERN)}`;
  const paceVariableCommand = `${consoleCommands.PACE_VARIABLE}=${getKeyByValue(
    paceVariableNames,
    paceVariable
  )}`;
  const paceHorizonVisual = visualFilters?.filter((filter) => filter?.id === paceHorizon);
  if (paceHorizonVisual) {
    const dd = paceHorizonVisual[0]?.values[0]?.replace(' days', '');
    paceHorizonCommand = `${consoleCommands.PACE_HORIZON}=${dd}`;
  }
  const lagAlignedCommand = `${consoleCommands.LAG_ALIGN}=${Number(lagAligned)}`;
  const cumulativeViewCommand = `${consoleCommands.CUMULATIVE_VIEW}=${Number(isCumulative)}`;
  const bookingForecastCommand = `${consoleCommands.BOOKING_FORECAST}=${paceForecast
    ?.map((forecast) => forecast?.split(': ')?.[1])
    ?.filter(Boolean)
    ?.join(',')}`;
  const alignmentCommand = `${consoleCommands.ALIGNMENT}=${Number(alignmentToggle)}`;
  const symmetricComparisonCommand = `${consoleCommands.SYMMETRIC}=${Number(
    symmetricComparisonToggle
  )}`;
  const compareWindowCommand = `${consoleCommands.COMPARE_WINDOW}=${getKeyByValue(
    compareWindows,
    dateComparison
  )}`;

  const percentageValue = percentageSchema(visualFilters, percentage)?.values?.[0];
  if (percentageValue) {
    percentageCommand = `${consoleCommands.PERCENTAGE}=${percentageValue}`;
  }
  //
  const paceTimeWidgetUrl = [
    pmsSyncCommand,
    hotelCommand,
    stayDateCommand,
    paceVariableCommand,
    paceHorizonCommand,
    lagAlignedCommand,
    cumulativeViewCommand,
    alignmentCommand,
    symmetricComparisonCommand,
    compareWindowCommand,
    bookingForecastCommand,
    percentageCommand,
  ]
    ?.filter(Boolean)
    .join('&')
    ?.replaceAll(/\s*|\t|\r|\n/gm, '');
  //
  return paceTimeWidgetUrl;
};

/**
 * Generate pace segment share url with selected filter schema
 * @param {Boolean} pmsSyncToggle - pms sync toggle state
 * @param {Object} hotelId - selected hotel
 * @param {Object} targetDate - stay date range
 * @param {Array} visualFilters - List of registered visual filters
 * @param {String} paceHorizon - pace horizon value
 * @param {Boolean} lagAligned - lag aligned toggle state
 * @param {Boolean} isCumulative - cumulative toggle state
 * @param {String} chartBreakdown - breakdown value
 * @param {Boolean} isDetailed - isDetailed state
 * @param {Array} focusOn - list of focus on values
 * @returns
 */
export const generatePaceSegmentShareUrl = (
  pmsSyncToggle,
  hotelId,
  targetDate,
  visualFilters,
  paceHorizon,
  lagAligned,
  isCumulative,
  chartBreakdown,
  isDetailed,
  focusOn,
  enableHotelGroups,
  hotelGroupId
) => {
  let paceHorizonCommand;
  let chartFilterCommand;
  //
  const pmsSyncCommand = `${consoleCommands.PMS}=${Number(pmsSyncToggle)}`;
  let hotelCommand;
  if (!enableHotelGroups) {
    hotelCommand = `${consoleCommands.HOTEL}=${hotelId?.id}`;
  } else {
    hotelCommand = `${consoleCommands.HOTEL_GROUP}=${hotelGroupId?.id
      ?.trim()
      ?.replaceAll(SHARE_URL_CONFIG.SPACE, SHARE_URL_CONFIG.ENCODED_SPACE)}`;
  }
  const stayDateCommand = `${consoleCommands.STAY_DATE}=${format(
    parseISO(targetDate?.startDate),
    ISO_YEAR_MONTH_DAY_PATTERN
  )}-${format(parseISO(targetDate?.endDate), ISO_YEAR_MONTH_DAY_PATTERN)}`;
  const paceHorizonVisual = visualFilters?.filter((filter) => filter?.id === paceHorizon);
  if (paceHorizonVisual) {
    const dd = paceHorizonVisual[0]?.values[0]?.replace(' days', '');
    paceHorizonCommand = `${consoleCommands.PACE_HORIZON}=${dd}`;
  }
  const lagAlignedCommand = `${consoleCommands.LAG_ALIGN}=${Number(lagAligned)}`;
  const cumulativeViewCommand = `${consoleCommands.CUMULATIVE_VIEW}=${Number(isCumulative)}`;
  const chartVisual = visualFilters?.find((filter) => filter?.id?.includes(chartBreakdown));
  if (chartVisual) {
    chartFilterCommand = `${consoleCommands.SEGMENT}=${getKeyByValue(
      breakdownList,
      chartVisual?.type
    )}${Number(isDetailed)}`;
  }
  let focusOnCommand;
  const focusOnValues = focusOn?.map((item) => item?.label);
  if (focusOnValues?.length > 0) {
    focusOnCommand = `${consoleCommands.FOCUS}=${focusOnValues
      ?.map((focus) => focus.replace(/ +/g, ''))
      ?.filter(Boolean)
      ?.join(',')}`;
  }
  //
  const paceWidgetSegmentUrl = [
    pmsSyncCommand,
    hotelCommand,
    stayDateCommand,
    paceHorizonCommand,
    lagAlignedCommand,
    cumulativeViewCommand,
    chartFilterCommand,
    focusOnCommand,
  ]
    ?.filter(Boolean)
    .join('&')
    ?.replaceAll(/\s*|\t|\r|\n/gm, '');
  //
  return paceWidgetSegmentUrl;
};

/**
 * Generate pace share url with selected filter schema
 * @param {*} pmsSyncToggle
 * @param {*} focusOn
 * @param {*} lagAligned
 * @param {*} isCumulative
 * @param {*} visualFilters
 * @param {*} targetDate
 * @param {*} hotelId
 * @param {*} paceHorizon
 * @param {*} enableHotelGroups
 * @param {*} hotelGroupId
 * @param {*} percentage
 * @param {*} alignmentToggle
 * @param {*} symmetricComparisonToggle
 * @param {*} dateComparison
 * @param {*} chartBreakdown
 * @param {*} isDetailed
 * @param {*} compareWindowCollapseToggle
 * @param {*} isPrimary
 * @param {*} isForecast
 * @param {*} revenueIsDisplayPS
 * @param {*} cxlCountIsDisplay
 * @param {*} occupiedRoomsIsDisplayPS
 * @param {*} adrIsDisplayPS
 * @param {*} cxlIsDisplayPS
 * @param {*} revenueIsDisplayPT
 * @param {*} occupiedRoomsIsDisplayPT
 * @param {*} adrIsDisplayPT
 * @param {*} cxlIsDisplayPT
 * @param {*} paceWindowSelection
 * @param {*} bookingDate
 * @param {*} hotelFocusOn
 * @param {*} enableSecondaryHotelGroups
 * @param {*} normalizedViewToggle
 * @param {*} activeTab
 * @param {*} revenueIsDisplayPH
 * @param {*} occupiedRoomsIsDisplayPH
 * @param {*} adrIsDisplayPH
 * @param {*} cxlIsDisplayPH
 * @param {String} localCurrency
 * @returns
 */
export const generatePaceShareUrl = (
  pmsSyncToggle,
  focusOn,
  lagAligned,
  isCumulative,
  visualFilters,
  targetDate,
  hotelId,
  paceHorizon,
  enableHotelGroups,
  hotelGroupId,
  percentage,
  alignmentToggle,
  symmetricComparisonToggle,
  dateComparison,
  chartBreakdown,
  isDetailed,
  compareWindowCollapseToggle,
  isPrimary,
  isForecast,
  revenueIsDisplayPS,
  cxlCountIsDisplay,
  occupiedRoomsIsDisplayPS,
  adrIsDisplayPS,
  cxlIsDisplayPS,
  revenueIsDisplayPT,
  occupiedRoomsIsDisplayPT,
  adrIsDisplayPT,
  cxlIsDisplayPT,
  paceWindowSelection,
  bookingDate,
  // hotelGroupFocusOn,
  hotelFocusOn,
  enableSecondaryHotelGroups,
  normalizedViewToggle,
  activeTab,
  revenueIsDisplayPH,
  occupiedRoomsIsDisplayPH,
  adrIsDisplayPH,
  cxlIsDisplayPH,
  tabularView,
  localCurrency
) => {
  let paceHorizonCommand;
  let chartFilterCommand;
  const bookingDateCommand = `${consoleCommands.BOOKING_DATE}=${format(
    parseISO(bookingDate?.startDate),
    ISO_YEAR_MONTH_DAY_PATTERN
  )}-${format(parseISO(bookingDate?.endDate), ISO_YEAR_MONTH_DAY_PATTERN)}`;
  const paceBookingWindowCommand = `${consoleCommands.BOOKING_PACE_WINDOW}=${paceWindowSelection}`;
  //
  const pmsSyncCommand = `${consoleCommands.PMS}=${Number(pmsSyncToggle)}`;
  let hotelCommand;
  if (!enableHotelGroups) {
    hotelCommand = `${consoleCommands.HOTEL}=${hotelId?.id}`;
  } else {
    hotelCommand = `${consoleCommands.HOTEL_GROUP}=${hotelGroupId?.id
      ?.trim()
      ?.replaceAll(SHARE_URL_CONFIG.SPACE, SHARE_URL_CONFIG.ENCODED_SPACE)}`;
  }
  const stayDateCommand = `${consoleCommands.STAY_DATE}=${format(
    parseISO(targetDate?.startDate),
    ISO_YEAR_MONTH_DAY_PATTERN
  )}-${format(parseISO(targetDate?.endDate), ISO_YEAR_MONTH_DAY_PATTERN)}`;
  const paceHorizonVisual = visualFilters?.filter((filter) => filter?.id === paceHorizon);
  if (paceHorizonVisual) {
    const dd = paceHorizonVisual[0]?.values[0]?.replace(' days', '');
    paceHorizonCommand = `${consoleCommands.PACE_HORIZON}=${dd}`;
  }
  const lagAlignedCommand = `${consoleCommands.LAG_ALIGN}=${Number(lagAligned)}`;
  const cumulativeViewCommand = `${consoleCommands.CUMULATIVE_VIEW}=${Number(isCumulative)}`;
  const chartVisual = visualFilters?.find((filter) => filter?.id?.includes(chartBreakdown));
  if (chartVisual) {
    chartFilterCommand = `${consoleCommands.SEGMENT}=${getKeyByValue(
      breakdownList,
      chartVisual?.type
    )}${Number(isDetailed)}`;
  }
  let focusOnCommand;
  const focusOnValues = focusOn?.map((item) => item?.label);
  if (focusOnValues?.length > 0) {
    focusOnCommand = `${consoleCommands.FOCUS}=${focusOnValues
      ?.map((focus) => focus.replace(/ +/g, ''))
      ?.filter(Boolean)
      ?.join(',')}`;
  }
  const alignmentCommand = `${consoleCommands.ALIGNMENT}=${Number(alignmentToggle)}`;
  const symmetricComparisonCommand = `${consoleCommands.SYMMETRIC}=${Number(
    symmetricComparisonToggle
  )}`;
  const compareWindowCommand = `${consoleCommands.COMPARE_WINDOW}=${getKeyByValue(
    compareWindows,
    dateComparison
  )}`;
  let percentageCommand;
  const percentageValue = percentageSchema(visualFilters, percentage)?.values?.[0];
  if (percentageValue) {
    percentageCommand = `${consoleCommands.PERCENTAGE}=${percentageValue}`;
  }
  const compareWindowCollapseToggleCommand = `${
    consoleCommands.SYMMETRIC_COMPARISON_COLLAPSE
  }=${Number(compareWindowCollapseToggle)}`;
  const breakdownToggleCommand = `${consoleCommands.BREAKDOWN_TOGGLE}=${Number(isPrimary)}`;

  const activeTabCommand = `${consoleCommands.ACTIVE_TREND_TAB}=${activeTab}`;
  const tabularViewCommand = `${consoleCommands.TABULAR_VIEW}=${Number(tabularView)}`;
  const localCurrencyCommand = `${consoleCommands.CURRENCY}=${localCurrency}`;
  //
  let visualCollapseCommand;
  if (activeTab === REPORT_TYPE.PACE_SEGMENT_WIDGET) {
    visualCollapseCommand = `${consoleCommands.VISUAL_LOADING}
     =${TABULAR_VIEWS.TOTAL_REVENUE}${Number(revenueIsDisplayPS)},
     ${TABULAR_VIEWS.TOTAL_OCCUPIEDROOMS}${Number(occupiedRoomsIsDisplayPS)},
     ${TABULAR_VIEWS.ADR}${Number(adrIsDisplayPS)},
     ${TABULAR_VIEWS.CANCELLATIONS}${Number(cxlIsDisplayPS)},
     ${TABULAR_VIEWS.CANCELLATIONS_COUNT}${Number(cxlCountIsDisplay)}
     `;
  }
  if (activeTab === REPORT_TYPE.PACE_TIME_WIDGET) {
    visualCollapseCommand = `${consoleCommands.VISUAL_LOADING}
    =${TABULAR_VIEWS.TOTAL_REVENUE}${Number(revenueIsDisplayPT)},
    ${TABULAR_VIEWS.TOTAL_OCCUPIEDROOMS}${Number(occupiedRoomsIsDisplayPT)},
    ${TABULAR_VIEWS.ADR}${Number(adrIsDisplayPT)},
    ${TABULAR_VIEWS.CANCELLATIONS}${Number(cxlIsDisplayPT)},${
      TABULAR_VIEWS.CANCELLATIONS_COUNT
    }${Number(cxlCountIsDisplay)}
    `;
  }
  if (activeTab === REPORT_TYPE.PACE_HOTEL_WIDGET) {
    visualCollapseCommand = `${consoleCommands.VISUAL_LOADING}
    =${TABULAR_VIEWS.TOTAL_REVENUE}${Number(revenueIsDisplayPH)},
    ${TABULAR_VIEWS.TOTAL_OCCUPIEDROOMS}${Number(occupiedRoomsIsDisplayPH)},
    ${TABULAR_VIEWS.ADR}${Number(adrIsDisplayPH)},
    ${TABULAR_VIEWS.CANCELLATIONS}${Number(cxlIsDisplayPH)},${
      TABULAR_VIEWS.CANCELLATIONS_COUNT
    }${Number(cxlCountIsDisplay)}
    `;
  }
  //
  const forecastToggleCommand = `${consoleCommands.FORECAST}=${Number(isForecast)}`;
  //
  let hotelComparisonCommand;
  if (!enableSecondaryHotelGroups) {
    hotelComparisonCommand = `${consoleCommands.COMPARE_HOTEL}=${hotelFocusOn?.id}`;
  } else {
    hotelComparisonCommand = `${consoleCommands.COMPARE_HOTEL_GROUP}=${hotelFocusOn?.id
      ?.trim()
      ?.replaceAll(SHARE_URL_CONFIG.SPACE, SHARE_URL_CONFIG.ENCODED_SPACE)}`;
  }
  const normalizedViewCommand = `${consoleCommands.NORMALIZED_VIEW}=${Number(
    normalizedViewToggle
  )}`;
  //
  const paceWidgetSegmentUrl = [
    pmsSyncCommand,
    hotelCommand,
    stayDateCommand,
    bookingDateCommand,
    paceHorizonCommand,
    lagAlignedCommand,
    cumulativeViewCommand,
    chartFilterCommand,
    focusOnCommand,
    alignmentCommand,
    symmetricComparisonCommand,
    percentageCommand,
    compareWindowCommand,
    compareWindowCollapseToggleCommand,
    breakdownToggleCommand,
    activeTabCommand,
    visualCollapseCommand,
    forecastToggleCommand,
    paceBookingWindowCommand,
    hotelComparisonCommand,
    normalizedViewCommand,
    tabularViewCommand,
    localCurrencyCommand,
  ]
    ?.filter(Boolean)
    .join('&')
    ?.replaceAll(/\s*|\t|\r|\n/gm, '');
  //
  return paceWidgetSegmentUrl;
};

/**
 *
 * @param {*} pmsSyncToggle
 * @param {*} hotelId
 * @param {*} targetDate
 * @param {*} vOTB
 * @param {*} bookingDate
 * @param {*} tabularView
 * @param {*} alignmentToggle
 * @param {*} symmetricComparisonToggle
 * @param {*} weeklyViewToggle
 * @param {*} isCumulative
 * @param {*} startDateComparison
 * @param {*} endDateComparison
 * @param {*} customDate
 * @param {*} dateComparison
 * @param {*} visualFilters
 * @param {*} chartBreakdown
 * @param {*} focusOn
 * @param {*} hotelFocusOn
 * @param {*} isDetailed
 * @param {*} enableHotelGroups
 * @param {*} hotelGroupId
 * @param {*} segmentGroup1
 * @param {*} segmentGroup2
 * @param {*} normalizedViewToggle
 * @param {*} enableSecondaryHotelGroups
 * @param {*} isTrendForecast
 * @param {*} activeTab
 * @param {*} revenueIsDisplayTT
 * @param {*} occupiedRoomsIsDisplayTT
 * @param {*} adrIsDisplayTT
 * @param {*} avgBookingWindowIsDisplayTT
 * @param {*} avgLosIsDisplayTT
 * @param {*} cxlIsDisplayTT
 * @param {*} revenueIsDisplayTS
 * @param {*} occupiedRoomsIsDisplayTS
 * @param {*} adrIsDisplayTS
 * @param {*} avgBookingWindowIsDisplayTS
 * @param {*} avgLosIsDisplayTS
 * @param {*} cxlIsDisplayTS
 * @param {*} revenueIsDisplayTH
 * @param {*} occupiedRoomsIsDisplayTH
 * @param {*} adrIsDisplayTH
 * @param {*} avgBookingWindowIsDisplayTH
 * @param {*} avgLosIsDisplayTH
 * @param {*} cxlIsDisplayTH
 * @param {*} compareWindowCollapseToggle
 * @param {*} isPrimary
 * @param {*} isPercentageChange
 * @param {*} isTrendDeAggregate
 * @returns
 */
export const generateTrendTimeShareUrl = (
  pmsSyncToggle,
  hotelId,
  targetDate,
  vOTB,
  bookingDate,
  tabularView,
  alignmentToggle,
  symmetricComparisonToggle,
  weeklyViewToggle,
  isCumulative,
  startDateComparison,
  endDateComparison,
  customDate,
  dateComparison,
  visualFilters,
  chartBreakdown,
  focusOn,
  hotelFocusOn,
  isDetailed,
  enableHotelGroups,
  hotelGroupId,
  segmentGroup1,
  segmentGroup2,
  normalizedViewToggle,
  enableSecondaryHotelGroups,
  isTrendForecast,
  activeTab,
  revenueIsDisplayTT,
  occupiedRoomsIsDisplayTT,
  adrIsDisplayTT,
  avgBookingWindowIsDisplayTT,
  avgLosIsDisplayTT,
  cxlIsDisplayTT,
  revenueIsDisplayTS,
  occupiedRoomsIsDisplayTS,
  adrIsDisplayTS,
  avgBookingWindowIsDisplayTS,
  avgLosIsDisplayTS,
  cxlIsDisplayTS,
  revenueIsDisplayTH,
  occupiedRoomsIsDisplayTH,
  adrIsDisplayTH,
  avgBookingWindowIsDisplayTH,
  avgLosIsDisplayTH,
  cxlIsDisplayTH,
  compareWindowCollapseToggle,
  isPrimary,
  isPercentageChange,
  isTrendDeAggregate,
  isMovingAverage,
  movingAverage,
  localCurrency
) => {
  let bookingDateCommand = `${consoleCommands.BOOKING_DATE}=`;
  //
  if (Number(vOTB) !== 0) {
    bookingDateCommand = bookingDateCommand.concat(
      `${format(parseISO(bookingDate?.startDate), ISO_YEAR_MONTH_DAY_PATTERN)}-${format(
        parseISO(bookingDate?.endDate),
        ISO_YEAR_MONTH_DAY_PATTERN
      )}`
    );
  } else {
    bookingDateCommand = bookingDateCommand.concat(commands.OTB);
  }
  const alignmentCommand = `${consoleCommands.ALIGNMENT}=${Number(alignmentToggle)}`;
  const symmetricComparisonCommand = `${consoleCommands.SYMMETRIC}=${Number(
    symmetricComparisonToggle
  )}`;
  const pmsSyncCommand = `${consoleCommands.PMS}=${Number(pmsSyncToggle)}`;
  const weeklyViewCommand = `${consoleCommands.WEEKLY_VIEW}=${Number(weeklyViewToggle)}`;
  const cumulativeViewCommand = `${consoleCommands.CUMULATIVE_VIEW}=${Number(isCumulative)}`;
  let hotelCommand;
  if (!enableHotelGroups) {
    hotelCommand = `${consoleCommands.HOTEL}=${hotelId?.id}`;
  } else {
    hotelCommand = `${consoleCommands.HOTEL_GROUP}=${hotelGroupId?.id
      ?.trim()
      ?.replaceAll(SHARE_URL_CONFIG.SPACE, SHARE_URL_CONFIG.ENCODED_SPACE)}`;
  }
  const stayDateCommand = `${consoleCommands.STAY_DATE}=${format(
    parseISO(targetDate?.startDate),
    ISO_YEAR_MONTH_DAY_PATTERN
  )}-${format(parseISO(targetDate?.endDate), ISO_YEAR_MONTH_DAY_PATTERN)}`;
  let customDateCommand;
  //
  if (
    startDateComparison &&
    endDateComparison &&
    customDate &&
    dateComparison &&
    dateComparison?.includes(KEYS.CUSTOM)
  ) {
    customDateCommand = `${consoleCommands.CUSTOM_DATES}=${customDate}-${
      customDate === KEYS.START_DATE
        ? format(parseISO(startDateComparison), ISO_YEAR_MONTH_DAY_PATTERN)
        : format(parseISO(endDateComparison), ISO_YEAR_MONTH_DAY_PATTERN)
    }`;
  }
  //
  const compareWindowCommand = `${consoleCommands.COMPARE_WINDOW}=${getKeyByValue(
    compareWindows,
    dateComparison
  )}`;
  const tabularViewCommand = `${consoleCommands.TABULAR_VIEW}=${Number(tabularView)}`;
  //
  let chartFilterCommand;
  let focusOnCommand;

  const chartVisual = visualFilters?.filter((filter) => filter?.id?.includes(chartBreakdown));
  if (chartVisual?.length) {
    chartFilterCommand = `${consoleCommands.SEGMENT}=${getKeyByValue(
      breakdownList,
      chartVisual[0]?.type
    )}${Number(isDetailed)}`;
  }
  const focusOnValues = focusOn?.map((item) => item?.label);
  if (focusOnValues?.length > 0) {
    focusOnCommand = `${consoleCommands.FOCUS}=${focusOnValues
      ?.map((focus) => focus.replace(/ +/g, ''))
      ?.filter(Boolean)
      ?.join(',')}`;
  }
  let movingAverageCommand;
  if (movingAverage !== null) {
    const list = getMovingAverageFilter(visualFilters);
    const option = list?.filter((avg) => avg?.id === movingAverage?.id);
    if (option?.length > 0) {
      movingAverageCommand = `${consoleCommands.MOVING_AVERAGE}=${Number(isMovingAverage)}${
        option[0]?.label
      }`;
    }
  }
  const currencyCommand = `${consoleCommands.CURRENCY}=${localCurrency}`;
  const optionsA = segmentGroup1?.map((item) => item?.label?.replace(/ +/g, ''));
  const optionsB = segmentGroup2?.map((item) => item?.label?.replace(/ +/g, ''));
  const setACommand = `${consoleCommands.SET_A}=${optionsA?.join(',')}`;
  const setBCommand = `${consoleCommands.SET_B}=${optionsB?.join(',')}`;
  //
  const normalizedViewCommand = `${consoleCommands.NORMALIZED_VIEW}=${Number(
    normalizedViewToggle
  )}`;

  let hotelComparisonCommand;
  if (!enableSecondaryHotelGroups) {
    hotelComparisonCommand = `${consoleCommands.COMPARE_HOTEL}=${hotelFocusOn?.id}`;
  } else {
    hotelComparisonCommand = `${consoleCommands.COMPARE_HOTEL_GROUP}=${hotelFocusOn?.id
      ?.trim()
      ?.replaceAll(SHARE_URL_CONFIG.SPACE, SHARE_URL_CONFIG.ENCODED_SPACE)}`;
  }

  const activeTabCommand = `${consoleCommands.ACTIVE_TREND_TAB}=${activeTab}`;
  let visualCollapseCommand;
  if (activeTab === REPORT_TYPE.TREND_TIME) {
    visualCollapseCommand = `${consoleCommands.VISUAL_LOADING}
     =${TABULAR_VIEWS.TOTAL_REVENUE}${Number(revenueIsDisplayTT)},
     ${TABULAR_VIEWS.TOTAL_OCCUPIEDROOMS}${Number(occupiedRoomsIsDisplayTT)},
     ${TABULAR_VIEWS.ADR}${Number(adrIsDisplayTT)},
     ${TABULAR_VIEWS.BOOKING_WINDOW}${Number(avgBookingWindowIsDisplayTT)},
     ${TABULAR_VIEWS.LENGTH_OF_STAY}${Number(avgLosIsDisplayTT)},
     ${TABULAR_VIEWS.CANCELLATIONS}${Number(cxlIsDisplayTT)}`.replaceAll(' ', '');
  }
  if (activeTab === REPORT_TYPE.TREND_SEGMENT) {
    visualCollapseCommand = `${consoleCommands.VISUAL_LOADING}
     =${TABULAR_VIEWS.TOTAL_REVENUE}${Number(revenueIsDisplayTS)},
     ${TABULAR_VIEWS.TOTAL_OCCUPIEDROOMS}${Number(occupiedRoomsIsDisplayTS)},
     ${TABULAR_VIEWS.ADR}${Number(adrIsDisplayTS)},
     ${TABULAR_VIEWS.BOOKING_WINDOW}${Number(avgBookingWindowIsDisplayTS)},
     ${TABULAR_VIEWS.LENGTH_OF_STAY}${Number(avgLosIsDisplayTS)},
     ${TABULAR_VIEWS.CANCELLATIONS}${Number(cxlIsDisplayTS)}`.replaceAll(' ', '');
  }
  if (activeTab === REPORT_TYPE.TREND_HOTEL) {
    visualCollapseCommand = `${consoleCommands.VISUAL_LOADING}
    =${TABULAR_VIEWS.TOTAL_REVENUE}${Number(revenueIsDisplayTH)},
    ${TABULAR_VIEWS.TOTAL_OCCUPIEDROOMS}${Number(occupiedRoomsIsDisplayTH)},
    ${TABULAR_VIEWS.ADR}${Number(adrIsDisplayTH)},
    ${TABULAR_VIEWS.BOOKING_WINDOW}${Number(avgBookingWindowIsDisplayTH)},
    ${TABULAR_VIEWS.LENGTH_OF_STAY}${Number(avgLosIsDisplayTH)},
    ${TABULAR_VIEWS.CANCELLATIONS}${Number(cxlIsDisplayTH)}`.replaceAll(' ', '');
  }
  const forecastToggleCommand = `${consoleCommands.FORECAST}=${Number(isTrendForecast)}`;
  const compareWindowCollapseToggleCommand = `${
    consoleCommands.SYMMETRIC_COMPARISON_COLLAPSE
  }=${Number(compareWindowCollapseToggle)}`;
  const breakdownToggleCommand = `${consoleCommands.BREAKDOWN_TOGGLE}=${Number(isPrimary)}`;
  const percentageToggleCommand = `${consoleCommands.PERCENTAGE_TOGGLE}=${Number(
    isPercentageChange
  )}`;

  const segmentBreakdownToggleCommand = `${consoleCommands.SEGMENT_BREAKDOWN}=${Number(
    isTrendDeAggregate
  )}`;

  const trendTimeUrl = [
    bookingDateCommand,
    alignmentCommand,
    weeklyViewCommand,
    cumulativeViewCommand,
    symmetricComparisonCommand,
    pmsSyncCommand,
    hotelCommand,
    stayDateCommand,
    compareWindowCommand,
    customDateCommand,
    tabularViewCommand,
    chartFilterCommand,
    focusOnCommand,
    setACommand,
    setBCommand,
    normalizedViewCommand,
    hotelComparisonCommand,
    activeTabCommand,
    visualCollapseCommand,
    forecastToggleCommand,
    compareWindowCollapseToggleCommand,
    breakdownToggleCommand,
    percentageToggleCommand,
    movingAverageCommand,
    currencyCommand,
    segmentBreakdownToggleCommand,
  ]
    ?.filter(Boolean)
    .join('&')
    ?.replaceAll(/\s*|\t|\r|\n/gm, '');
  //
  return trendTimeUrl;
};

/**
 * Generate trend segment share url with selected filter schema
 * @param {Boolean} pmsSyncToggle - pms sync toggle state
 * @param {Object} hotelId - selected hotel
 * @param {Object} targetDate - stay date range
 * @param {Array} visualFilters - List of registered visual filters
 * @param {String} chartBreakdown - breakdown value
 * @param {Boolean} isDetailed - isDetailed state
 * @param {String} vOTB - vOTB value
 * @param {Object} bookingDate - booking date range
 * @param {Boolean} weeklyViewToggle - weekly view toggle state
 * @param {Boolean} isCumulative - cumulative toggle state
 * @param {Array} focusOn - list of focus on values
 * @param {Array} segmentGroup1 - list of segment group 1 values
 * @param {Array} segmentGroup2 - list of segment group 2 values
 * @param {Boolean} totalRevenueTabularView - state of tabular view enabled for total revenue
 * @param {Boolean} totalOccupiedRoomTabularView - state of tabular view enabled for total occupied rooms
 * @param {Boolean} adrTabularView - state of tabular view enabled for ADR
 * @param {Boolean} bookingWindowTabularView - state of tabular view enabled for booking window
 * @param {Boolean} lengthOfStayTabularView - state of tabular view enabled for length of stay
 * @param {Boolean} numberOfCancellationTabularView - state of tabular view enabled for number of cancellations
 * @returns
 */
export const generateTrendSegmentShareUrl = (
  pmsSyncToggle,
  hotelId,
  targetDate,
  visualFilters,
  chartBreakdown,
  isDetailed,
  vOTB,
  bookingDate,
  weeklyViewToggle,
  isCumulative,
  focusOn,
  segmentGroup1,
  segmentGroup2,
  totalRevenueTabularView,
  totalOccupiedRoomTabularView,
  adrTabularView,
  bookingWindowTabularView,
  lengthOfStayTabularView,
  numberOfCancellationTabularView,
  enableHotelGroups,
  hotelGroupId
) => {
  let focusOnCommand;
  let chartFilterCommand;
  //
  let bookingDateCommand = `${consoleCommands.BOOKING_DATE}=`;
  const chartVisual = visualFilters?.filter((filter) => filter?.id?.includes(chartBreakdown));
  if (chartVisual?.length) {
    chartFilterCommand = `${consoleCommands.SEGMENT}=${getKeyByValue(
      breakdownList,
      chartVisual[0]?.type
    )}${Number(isDetailed)}`;
  }
  if (vOTB === 'Custom Booking Window') {
    bookingDateCommand = bookingDateCommand.concat(
      `${format(parseISO(bookingDate?.startDate), ISO_YEAR_MONTH_DAY_PATTERN)}-${format(
        parseISO(bookingDate?.endDate),
        ISO_YEAR_MONTH_DAY_PATTERN
      )}`
    );
  } else {
    bookingDateCommand = bookingDateCommand.concat(commands.OTB);
  }
  const pmsSyncCommand = `${consoleCommands.PMS}=${Number(pmsSyncToggle)}`;
  const weeklyViewCommand = `${consoleCommands.WEEKLY_VIEW}=${Number(weeklyViewToggle)}`;
  const cumulativeViewCommand = `${consoleCommands.CUMULATIVE_VIEW}=${Number(isCumulative)}`;
  let hotelCommand;
  if (!enableHotelGroups) {
    hotelCommand = `${consoleCommands.HOTEL}=${hotelId?.id}`;
  } else {
    hotelCommand = `${consoleCommands.HOTEL_GROUP}=${hotelGroupId?.id
      ?.trim()
      ?.replaceAll(SHARE_URL_CONFIG.SPACE, SHARE_URL_CONFIG.ENCODED_SPACE)}`;
  }
  const stayDateCommand = `${consoleCommands.STAY_DATE}=${format(
    parseISO(targetDate?.startDate),
    ISO_YEAR_MONTH_DAY_PATTERN
  )}-${format(parseISO(targetDate?.endDate), ISO_YEAR_MONTH_DAY_PATTERN)}`;
  const focusOnValues = focusOn?.map((item) => item?.label);
  if (focusOnValues?.length > 0) {
    focusOnCommand = `${consoleCommands.FOCUS}=${focusOnValues
      ?.map((focus) => focus.replace(/ +/g, ''))
      ?.filter(Boolean)
      ?.join(',')}`;
  }
  //
  const optionsA = segmentGroup1?.map((item) => item?.label?.replace(/ +/g, ''));
  const optionsB = segmentGroup2?.map((item) => item?.label?.replace(/ +/g, ''));
  const setACommand = `${consoleCommands.SET_A}=${optionsA?.join(',')}`;
  const setBCommand = `${consoleCommands.SET_B}=${optionsB?.join(',')}`;
  const tabularViewCommand = `${consoleCommands.TABULAR_VIEW}=${
    TABULAR_VIEWS.TOTAL_REVENUE
  }${Number(totalRevenueTabularView)},
  ${TABULAR_VIEWS.TOTAL_OCCUPIEDROOMS}${Number(totalOccupiedRoomTabularView)},
  ${TABULAR_VIEWS.ADR}${Number(adrTabularView)},
  ${TABULAR_VIEWS.BOOKING_WINDOW}${Number(bookingWindowTabularView)},
  ${TABULAR_VIEWS.LENGTH_OF_STAY}${Number(lengthOfStayTabularView)},
  ${TABULAR_VIEWS.CANCELLATIONS}${Number(numberOfCancellationTabularView)}`;
  //
  const trendSegmentUrl = [
    bookingDateCommand,
    weeklyViewCommand,
    cumulativeViewCommand,
    pmsSyncCommand,
    hotelCommand,
    stayDateCommand,
    focusOnCommand,
    chartFilterCommand,
    setACommand,
    setBCommand,
    tabularViewCommand,
  ]
    ?.filter(Boolean)
    .join('&')
    ?.replaceAll(/\s*|\t|\r|\n/gm, '');
  //
  return trendSegmentUrl;
};

/**
 * Generate trend hotel share url with selected filter schema
 * @param {Boolean} pmsSyncToggle - pms sync toggle state
 * @param {Object} hotelId - selected hotel
 * @param {Object} targetDate - stay date range
 * @param {String} vOTB - vOTB value
 * @param {Object} bookingDate - booking date range
 * @param {Boolean} weeklyViewToggle - weekly view toggle state
 * @param {Boolean} isCumulative - cumulative toggle state
 * @param {Boolean} normalizedViewToggle - normalized  view toggle state
 * @param {Array} focusOn - list of focus on values
 * @param {Boolean} totalRevenueTabularView - state of tabular view enabled for total revenue
 * @param {Boolean} totalOccupiedRoomTabularView - state of tabular view enabled for total occupied rooms
 * @param {Boolean} adrTabularView - state of tabular view enabled for ADR
 * @param {Boolean} bookingWindowTabularView - state of tabular view enabled for booking window
 * @param {Boolean} lengthOfStayTabularView - state of tabular view enabled for length of stay
 * @param {Boolean} numberOfCancellationTabularView - state of tabular view enabled for number of cancellations
 * @returns
 */
export const generateTrendHotelShareUrl = (
  pmsSyncToggle,
  hotelId,
  targetDate,
  vOTB,
  bookingDate,
  weeklyViewToggle,
  isCumulative,
  normalizedViewToggle,
  focusOn,
  totalRevenueTabularView,
  totalOccupiedRoomTabularView,
  adrTabularView,
  bookingWindowTabularView,
  lengthOfStayTabularView,
  numberOfCancellationTabularView,
  enableHotelGroups,
  hotelGroupId
) => {
  let bookingDateCommand = `${consoleCommands.BOOKING_DATE}=`;
  //
  if (vOTB === 'Custom Booking Window') {
    bookingDateCommand = bookingDateCommand.concat(
      `${format(parseISO(bookingDate?.startDate), ISO_YEAR_MONTH_DAY_PATTERN)}-${format(
        parseISO(bookingDate?.endDate),
        ISO_YEAR_MONTH_DAY_PATTERN
      )}`
    );
  } else {
    bookingDateCommand = bookingDateCommand.concat(commands.OTB);
  }
  const pmsSyncCommand = `${consoleCommands.PMS}=${Number(pmsSyncToggle)}`;
  const weeklyViewCommand = `${consoleCommands.WEEKLY_VIEW}=${Number(weeklyViewToggle)}`;
  const cumulativeViewCommand = `${consoleCommands.CUMULATIVE_VIEW}=${Number(isCumulative)}`;
  const normalizedViewCommand = `${consoleCommands.NORMALIZED_VIEW}=${Number(
    normalizedViewToggle
  )}`;
  //
  let hotelCommand;
  if (!enableHotelGroups) {
    hotelCommand = `${consoleCommands.HOTEL}=${hotelId?.id}`;
  } else {
    hotelCommand = `${consoleCommands.HOTEL_GROUP}=${hotelGroupId?.id
      ?.trim()
      ?.replaceAll(SHARE_URL_CONFIG.SPACE, SHARE_URL_CONFIG.ENCODED_SPACE)}`;
  }
  const focusOnValues = focusOn?.map((item) => item?.id);
  const focusOnHotelsCommand = `${consoleCommands.COMPARE_HOTEL}=${focusOnValues?.join(',')}`;
  const stayDateCommand = `${consoleCommands.STAY_DATE}=${format(
    parseISO(targetDate?.startDate),
    ISO_YEAR_MONTH_DAY_PATTERN
  )}-${format(parseISO(targetDate?.endDate), ISO_YEAR_MONTH_DAY_PATTERN)}`;
  //
  const tabularViewCommand = `${consoleCommands.TABULAR_VIEW}=${
    TABULAR_VIEWS.TOTAL_REVENUE
  }${Number(totalRevenueTabularView)},
  ${TABULAR_VIEWS.TOTAL_OCCUPIEDROOMS}${Number(totalOccupiedRoomTabularView)},
  ${TABULAR_VIEWS.ADR}${Number(adrTabularView)},
  ${TABULAR_VIEWS.BOOKING_WINDOW}${Number(bookingWindowTabularView)},
  ${TABULAR_VIEWS.LENGTH_OF_STAY}${Number(lengthOfStayTabularView)},
  ${TABULAR_VIEWS.CANCELLATIONS}${Number(numberOfCancellationTabularView)}`;
  //
  const trendHotelsUrl = [
    bookingDateCommand,
    focusOnHotelsCommand,
    weeklyViewCommand,
    cumulativeViewCommand,
    normalizedViewCommand,
    pmsSyncCommand,
    hotelCommand,
    stayDateCommand,
    tabularViewCommand,
  ]
    ?.filter(Boolean)
    .join('&')
    ?.replaceAll(/\s*|\t|\r|\n/gm, '');
  //
  return trendHotelsUrl;
};

/**
 * Generate regional map share url with selected filter schema
 * @param {Boolean} pmsSyncToggle - pms sync toggle state
 * @param {Object} hotelId - selected hotel
 * @param {Object} targetDate - stay date range
 * @param {Boolean} customBooking - is custom booking option enabled state
 * @param {Array} visualFilters - List of registered visual filters
 * @param {String} chartBreakdown - breakdown value
 * @param {Boolean} isDetailed - isDetailed state
 * @param {Object} bookingDate - booking date range
 * @param {Array} focusOn - list of focus on values
 * @param {Boolean} regionalBreakdownTabularView - state of tabular view enabled for regional breakdown
 * @param {String} localCurrency - selected currency state
 * @returns
 */
export const generateRegionalMapShareUrl = (
  pmsSyncToggle,
  hotelId,
  targetDate,
  customBooking,
  visualFilters,
  chartBreakdown,
  isDetailed,
  bookingDate,
  focusOn,
  regionalBreakdownTabularView,
  hotelGroupId,
  enableHotelGroups,
  localCurrency
) => {
  let chartFilterCommand;
  let focusOnCommand;
  //
  const chartVisual = visualFilters?.filter((filter) => filter?.id?.includes(chartBreakdown));
  if (chartVisual?.length) {
    chartFilterCommand = `${consoleCommands.SEGMENT}=${getKeyByValue(
      breakdownList,
      chartVisual[0]?.type
    )}${Number(isDetailed)}`;
  }
  let bookingDateCommand = `${consoleCommands.BOOKING_DATE}=`;
  if (customBooking) {
    bookingDateCommand = bookingDateCommand.concat(
      `${format(parseISO(bookingDate?.startDate), ISO_YEAR_MONTH_DAY_PATTERN)}-${format(
        parseISO(bookingDate?.endDate),
        ISO_YEAR_MONTH_DAY_PATTERN
      )}`
    );
  } else {
    bookingDateCommand = bookingDateCommand.concat(commands.OTB);
  }
  const pmsSyncCommand = `${consoleCommands.PMS}=${Number(pmsSyncToggle)}`;
  let hotelCommand;
  if (!enableHotelGroups) {
    hotelCommand = `${consoleCommands.HOTEL}=${hotelId?.id}`;
  } else {
    hotelCommand = `${consoleCommands.HOTEL_GROUP}=${hotelGroupId?.id
      ?.trim()
      ?.replaceAll(SHARE_URL_CONFIG.SPACE, SHARE_URL_CONFIG.ENCODED_SPACE)}`;
  }
  const stayDateCommand = `${consoleCommands.STAY_DATE}=${format(
    parseISO(targetDate?.startDate),
    ISO_YEAR_MONTH_DAY_PATTERN
  )}-${format(parseISO(targetDate?.endDate), ISO_YEAR_MONTH_DAY_PATTERN)}`;
  const focusOnValues = focusOn?.map((item) => item?.label);
  if (focusOnValues?.length > 0) {
    focusOnCommand = `${consoleCommands.FOCUS}=${focusOnValues
      ?.map((focus) => focus.replace(/ +/g, ''))
      ?.filter(Boolean)
      ?.join(',')}`;
  }
  const tabularViewCommand = `${consoleCommands.TABULAR_VIEW}=${
    TABULAR_VIEWS.REGIONAL_BREAKDOWN
  }${Number(regionalBreakdownTabularView)}`;
  const localCurrencyCommand = `${consoleCommands.CURRENCY}=${localCurrency}`;
  //
  const regionalMapUrl = [
    chartFilterCommand,
    bookingDateCommand,
    pmsSyncCommand,
    hotelCommand,
    stayDateCommand,
    focusOnCommand,
    tabularViewCommand,
    localCurrencyCommand,
  ]
    ?.filter(Boolean)
    ?.join('&')
    ?.replaceAll(/\s*|\t|\r|\n/gm, '');
  //
  return regionalMapUrl;
};

/**
 * Generate 3d plot share url with selected filter schema
 * @param {Boolean} pmsSyncToggle - pms sync toggle state
 * @param {Object} hotelId - selected hotel
 * @param {Object} targetDate - stay date range
 * @param {Array} visualFilters - List of registered visual filters
 * @param {String} plotPaceHorizon - plot pace horizon value
 * @param {Boolean} isOccupied - state of pace variable toggle
 * @returns
 */
export const generatePlotShareUrl = (
  pmsSyncToggle,
  hotelId,
  targetDate,
  visualFilters,
  plotPaceHorizon,
  isOccupied,
  enableHotelGroups,
  hotelGroupId
) => {
  let paceHorizonCommand;
  let paceVariableCommand;
  //
  const pmsSyncCommand = `${consoleCommands.PMS}=${Number(pmsSyncToggle)}`;
  let hotelCommand;
  if (!enableHotelGroups) {
    hotelCommand = `${consoleCommands.HOTEL}=${hotelId?.id}`;
  } else {
    hotelCommand = `${consoleCommands.HOTEL_GROUP}=${hotelGroupId?.id
      ?.trim()
      ?.replaceAll(SHARE_URL_CONFIG.SPACE, SHARE_URL_CONFIG.ENCODED_SPACE)}`;
  }
  const stayDateCommand = `${consoleCommands.STAY_DATE}=${format(
    parseISO(targetDate?.startDate),
    ISO_YEAR_MONTH_DAY_PATTERN
  )}-${format(parseISO(targetDate?.endDate), ISO_YEAR_MONTH_DAY_PATTERN)}`;
  if (isOccupied) {
    paceVariableCommand = `${consoleCommands.PACE_VARIABLE}=${getKeyByValue(
      paceVariableNames,
      paceVariableNames.rms
    )}`;
  } else {
    paceVariableCommand = `${consoleCommands.PACE_VARIABLE}=${getKeyByValue(
      paceVariableNames,
      paceVariableNames.rev
    )}`;
  }
  const paceHorizonVisual = visualFilters?.filter((filter) => filter?.id === plotPaceHorizon);
  if (paceHorizonVisual) {
    const horizon = paceHorizonVisual[0]?.values[0]?.replace(' days', '');
    paceHorizonCommand = `${consoleCommands.PACE_HORIZON}=${horizon}`;
  }
  const plotUrl = [
    pmsSyncCommand,
    hotelCommand,
    stayDateCommand,
    paceVariableCommand,
    paceHorizonCommand,
  ]
    ?.filter(Boolean)
    .join('&')
    ?.replaceAll(/\s*|\t|\r|\n/gm, '');
  //
  return plotUrl;
};

/**
 * Generate web clicks share url with selected filter schema
 * @param {Boolean} pmsSyncToggle - pms sync toggle state
 * @param {Object} hotelId - selected hotel
 * @param {Object} targetDate - stay date range
 * @param {String} dateComparison - date comparison value
 * @param {String} vOTB - vOTB value
 * @param {Array} visualFilters - List of registered visual filters
 * @param {String} paceHorizon - pace horizon value
 * @param {Date} startDate  - start date of custom option
 * @param {Date} endDate - end date of custom option
 * @param {String} customDate - type of custom date option
 * @param {Boolean} weeklyViewToggle - weekly view toggle state
 * @param {Boolean} isCumulative - cumulative toggle state
 * @param {Boolean} normalizedViewToggle - normalized  view toggle state
 * @param {Array} webClickStep - list of web click steps values
 * @param {Number} scalingFactor - value of scale
 * @param {Array} hotelFocusOn - list of hotels focus on values
 * @param {Array} focusOn - list of focus on values
 * @param {Object} bookingDate - booking date range
 * @param {Boolean} alignmentToggle - alignment toggle state
 * @param {Boolean} symmetricComparisonToggle - symmetric alignment toggle state
 * @param {*} enableHotelGroups
 * @param {*} hotelGroupId
 * @param {*} enableSecondaryHotelGroups
 * @param {*} utmFocusOn
 * @param {*} utmBreakdown
 * @param {*} timeTrendIsDisplay
 * @param {*} timePaceIsDisplay
 * @param {*} segmentTrendIsDisplay
 * @param {*} segmentPaceIsDisplay
 * @param {*} hotelTrendIsDisplay
 * @param {*} hotelPaceIsDisplay
 * @param {*} utmTrendIsDisplay
 * @param {*} utmPaceIsDisplay
 * @returns
 */
export const generateWebClicksShareUrl = (
  pmsSyncToggle,
  hotelId,
  targetDate,
  dateComparison,
  vOTB,
  visualFilters,
  paceHorizon,
  startDate,
  endDate,
  customDate,
  weeklyViewToggle,
  isCumulative,
  normalizedViewToggle,
  webClickStep,
  scalingFactor,
  hotelFocusOn,
  focusOn,
  bookingDate,
  alignmentToggle,
  symmetricComparisonToggle,
  enableHotelGroups,
  hotelGroupId,
  enableSecondaryHotelGroups,
  // hotelGroupFocusOn,
  compareWindowCollapseToggle,
  utmFocusOn,
  utmBreakdown,
  timeTrendIsDisplay,
  timePaceIsDisplay,
  segmentTrendIsDisplay,
  segmentPaceIsDisplay,
  hotelTrendIsDisplay,
  hotelPaceIsDisplay,
  utmTrendIsDisplay,
  utmPaceIsDisplay,
  localCurrency
) => {
  let bookingDateCommand = `${consoleCommands.BOOKING_DATE}=`;
  if (vOTB) {
    bookingDateCommand = bookingDateCommand.concat(
      `${format(parseISO(bookingDate?.startDate), ISO_YEAR_MONTH_DAY_PATTERN)}-${format(
        parseISO(bookingDate?.endDate),
        ISO_YEAR_MONTH_DAY_PATTERN
      )}`
    );
  } else {
    bookingDateCommand = bookingDateCommand.concat(commands.OTB);
  }
  //
  const alignmentCommand = `${consoleCommands.ALIGNMENT}=${Number(alignmentToggle)}`;
  const symmetricComparisonCommand = `${consoleCommands.SYMMETRIC}=${Number(
    symmetricComparisonToggle
  )}`;
  const pmsSyncCommand = `${consoleCommands.PMS}=${Number(pmsSyncToggle)}`;
  let hotelCommand;
  if (!enableHotelGroups) {
    hotelCommand = `${consoleCommands.HOTEL}=${hotelId?.id}`;
  } else {
    hotelCommand = `${consoleCommands.HOTEL_GROUP}=${hotelGroupId?.id
      ?.trim()
      ?.replaceAll(SHARE_URL_CONFIG.SPACE, SHARE_URL_CONFIG.ENCODED_SPACE)}`;
  }
  const stayDateCommand = `${consoleCommands.STAY_DATE}=${format(
    parseISO(targetDate?.startDate),
    ISO_YEAR_MONTH_DAY_PATTERN
  )}-${format(parseISO(targetDate?.endDate), ISO_YEAR_MONTH_DAY_PATTERN)}`;
  const compareWindowCommand = `${consoleCommands.COMPARE_WINDOW}=${getKeyByValue(
    compareWindows,
    dateComparison
  )}`;
  const weeklyViewCommand = `${consoleCommands.WEEKLY_VIEW}=${Number(weeklyViewToggle)}`;
  const cumulativeViewCommand = `${consoleCommands.CUMULATIVE_VIEW}=${Number(isCumulative)}`;
  const normalizedViewCommand = `${consoleCommands.NORMALIZED_VIEW}=${Number(
    normalizedViewToggle
  )}`;
  let paceHorizonCommand;
  const paceHorizonVisual = visualFilters?.filter((filter) => filter?.id === paceHorizon);
  if (paceHorizonVisual) {
    const dd = paceHorizonVisual[0]?.values[0]?.replace(' days', '');
    paceHorizonCommand = `${consoleCommands.PACE_HORIZON}=${dd}`;
  }
  const scaleFactorCommand = `${consoleCommands.WEB_CLICK_SCALE}=${Number(scalingFactor)}`;
  const stepValues = webClickStep?.map((item) => item?.label?.replace(/ +/g, ''));
  const stepCommand = `${consoleCommands.WEB_CLICK_STEPS}=${stepValues?.join(',')}`;

  let focusOnCommand;
  const focusOnValues = focusOn?.map((item) => item?.label);
  if (focusOnValues?.length > 0) {
    focusOnCommand = `${consoleCommands.FOCUS}=${focusOnValues
      ?.map((focus) => focus.replace(/ +/g, ''))
      ?.filter(Boolean)
      ?.join(',')}`;
  }
  const utmBreakdownCommand = `${consoleCommands.UTM_BREAKDOWN}=${utmBreakdown}`;
  let utmFocusOnCommand;
  // if (!isEmpty(utmFocusOn)) {
  //   const utmFocusOnValues = utmFocusOn?.map((item) => item?.label);
  //   if (utmFocusOnValues?.length > 0) {
  //     utmFocusOnCommand = `${consoleCommands.UTM_FOCUS_ON}=${utmFocusOnValues
  //       ?.map((focus) => focus.replace(/ +/g, ''))
  //       ?.filter(Boolean)
  //       ?.join(',')}`;
  //   }
  // }
  let customDateCommand;
  if (startDate && endDate && customDate && dateComparison === KEYS.CUSTOM) {
    customDateCommand = `${consoleCommands.CUSTOM_DATES}=${customDate}-${
      customDate === KEYS.START_DATE
        ? format(parseISO(startDate), ISO_YEAR_MONTH_DAY_PATTERN)
        : format(parseISO(endDate), ISO_YEAR_MONTH_DAY_PATTERN)
    }`;
  }
  let hotelComparisonCommand;
  if (!enableSecondaryHotelGroups) {
    hotelComparisonCommand = `${consoleCommands.COMPARE_HOTEL}=${hotelFocusOn?.id}`;
  } else {
    hotelComparisonCommand = `${consoleCommands.COMPARE_HOTEL_GROUP}=${hotelFocusOn?.id
      ?.trim()
      ?.replaceAll(SHARE_URL_CONFIG.SPACE, SHARE_URL_CONFIG.ENCODED_SPACE)}`;
  }
  const compareWindowCollapseToggleCommand = `${
    consoleCommands.SYMMETRIC_COMPARISON_COLLAPSE
  }=${Number(compareWindowCollapseToggle)}`;
  //
  const visualCollapseCommand = `${consoleCommands.VISUAL_LOADING}=${COLLAPSE_VISUALS.TT}${Number(
    timeTrendIsDisplay
  )},
  ${COLLAPSE_VISUALS.TP}${Number(timePaceIsDisplay)},
  ${COLLAPSE_VISUALS.ST}${Number(segmentTrendIsDisplay)},
  ${COLLAPSE_VISUALS.SP}${Number(segmentPaceIsDisplay)},
  ${COLLAPSE_VISUALS.HT}${Number(hotelTrendIsDisplay)},
  ${COLLAPSE_VISUALS.HP}${Number(hotelPaceIsDisplay)},
  ${COLLAPSE_VISUALS.UT}${Number(utmTrendIsDisplay)},
  ${COLLAPSE_VISUALS.UP}${Number(utmPaceIsDisplay)}`.replaceAll(' ', '');
  const currencyCommand = `${consoleCommands.CURRENCY}=${localCurrency}`;
  //
  const webClickUrl = [
    bookingDateCommand,
    alignmentCommand,
    symmetricComparisonCommand,
    weeklyViewCommand,
    cumulativeViewCommand,
    normalizedViewCommand,
    scaleFactorCommand,
    stepCommand,
    pmsSyncCommand,
    hotelCommand,
    stayDateCommand,
    compareWindowCommand,
    paceHorizonCommand,
    focusOnCommand,
    customDateCommand,
    hotelComparisonCommand,
    utmFocusOnCommand,
    utmBreakdownCommand,
    compareWindowCollapseToggleCommand,
    visualCollapseCommand,
    currencyCommand,
  ]
    ?.filter(Boolean)
    .join('&')
    ?.replaceAll(/\s*|\t|\r|\n/gm, '');
  //
  return webClickUrl;
};

/**
 * Generate i prefer share url with selected filter schema
 * @param {Boolean} pmsSyncToggle - pms sync toggle state
 * @param {Object} hotelId - selected hotel
 * @param {Object} targetDate - stay date range
 * @param {String} dateComparison - date comparison value
 * @param {String} vOTB - vOTB value
 * @param {Array} visualFilters - List of registered visual filters
 * @param {Date} startDate  - start date of custom option
 * @param {Date} endDate - end date of custom option
 * @param {String} customDate - type of custom date option
 * @param {Array} focusOn - list of focus on values
 * @param {Object} bookingDate - booking date range
 * @param {Boolean} alignmentToggle - alignment toggle state
 * @param {Boolean} symmetricComparisonToggle - symmetric alignment toggle state
 * @param {String} chartBreakdown - breakdown value
 * @param {Boolean} isDetailed - isDetailed state
 * @param {String} timeFrame - time frame value
 * @param {String} metricSelection - metric selection value
 * @returns
 */
export const generateIPreferShareUrl = (
  pmsSyncToggle,
  hotelId,
  targetDate,
  dateComparison,
  vOTB,
  visualFilters,
  startDate,
  endDate,
  customDate,
  focusOn,
  bookingDate,
  alignmentToggle,
  symmetricComparisonToggle,
  chartBreakdown,
  isDetailed,
  timeFrame,
  metricSelection,
  enableHotelGroups,
  hotelGroupId,
  localCurrency
) => {
  let bookingDateCommand = `${consoleCommands.BOOKING_DATE}=`;
  let chartFilterCommand;
  if (vOTB) {
    bookingDateCommand = bookingDateCommand.concat(
      `${format(parseISO(bookingDate?.startDate), ISO_YEAR_MONTH_DAY_PATTERN)}-${format(
        parseISO(bookingDate?.endDate),
        ISO_YEAR_MONTH_DAY_PATTERN
      )}`
    );
  } else {
    bookingDateCommand = bookingDateCommand.concat(commands.OTB);
  }
  //
  const alignmentCommand = `${consoleCommands.ALIGNMENT}=${Number(alignmentToggle)}`;
  const symmetricComparisonCommand = `${consoleCommands.SYMMETRIC}=${Number(
    symmetricComparisonToggle
  )}`;
  const chartVisual = visualFilters?.filter((filter) => filter?.id?.includes(chartBreakdown));
  if (chartVisual?.length) {
    chartFilterCommand = `${consoleCommands.SEGMENT}=${getKeyByValue(
      breakdownList,
      chartVisual[0]?.type
    )}${Number(isDetailed)}`;
  }
  const pmsSyncCommand = `${consoleCommands.PMS}=${Number(pmsSyncToggle)}`;
  let hotelCommand;
  if (!enableHotelGroups) {
    hotelCommand = `${consoleCommands.HOTEL}=${hotelId?.id}`;
  } else {
    hotelCommand = `${consoleCommands.HOTEL_GROUP}=${hotelGroupId?.id
      ?.trim()
      ?.replaceAll(SHARE_URL_CONFIG.SPACE, SHARE_URL_CONFIG.ENCODED_SPACE)}`;
  }
  const stayDateCommand = `${consoleCommands.STAY_DATE}=${format(
    parseISO(targetDate?.startDate),
    ISO_YEAR_MONTH_DAY_PATTERN
  )}-${format(parseISO(targetDate?.endDate), ISO_YEAR_MONTH_DAY_PATTERN)}`;
  const compareWindowCommand = `${consoleCommands.COMPARE_WINDOW}=${getKeyByValue(
    compareWindows,
    dateComparison
  )}`;
  const metricSelectionCommand = `${consoleCommands.METRIC_SELECTION}=${getKeyByValue(
    paceVariableNames,
    metricSelection
  )}`;
  let timeFrameCommand;
  const timeFrameVisualFilter = visualFilters?.find((filter) => filter?.id === timeFrame)
    ?.values[0];
  if (timeFrameVisualFilter) {
    timeFrameCommand = `${consoleCommands.TIME_FRAME}=${getKeyByValue(
      timeFrameOptions,
      timeFrameVisualFilter
    )}`;
  }
  let focusOnCommand;
  const focusOnValues = focusOn?.map((item) => item?.label);
  if (focusOnValues?.length > 0) {
    focusOnCommand = `${consoleCommands.FOCUS}=${focusOnValues
      ?.map((focus) => focus.replace(/ +/g, ''))
      ?.filter(Boolean)
      ?.join(',')}`;
  }
  let customDateCommand;
  if (startDate && endDate && customDate && dateComparison === KEYS.CUSTOM) {
    customDateCommand = `${consoleCommands.CUSTOM_DATES}=${customDate}-${
      customDate === KEYS.START_DATE
        ? format(parseISO(startDate), ISO_YEAR_MONTH_DAY_PATTERN)
        : format(parseISO(endDate), ISO_YEAR_MONTH_DAY_PATTERN)
    }`;
  }
  const currencyCommand = `${consoleCommands.CURRENCY}=${localCurrency}`;
  //
  const iPreferUrl = [
    chartFilterCommand,
    bookingDateCommand,
    alignmentCommand,
    symmetricComparisonCommand,
    pmsSyncCommand,
    hotelCommand,
    stayDateCommand,
    compareWindowCommand,
    metricSelectionCommand,
    focusOnCommand,
    customDateCommand,
    timeFrameCommand,
    currencyCommand,
  ]
    ?.filter(Boolean)
    .join('&')
    ?.replaceAll(/\s*|\t|\r|\n/gm, '');
  //
  return iPreferUrl;
};

/**
 * Generate power bi filter schema for pace widget forecast filter
 * @param {Array} forecastFilter
 * @param {Object} targetDate
 * @param {Boolean} pmsSyncToggle
 * @param {String} paceVariable
 * @returns
 */
export const getPaceForecastFilter = (forecastFilter, targetDate, pmsSyncToggle, paceVariable) => {
  const paceForecastFilter = forecastFilter
    ?.filter(
      (filter) =>
        filter?.stayDate !== null &&
        filter?.stayDate >= formatDate(targetDate.startDate, YEAR_MONTH_DAY_PATTERN) &&
        filter?.stayDate <= formatDate(targetDate.endDate, YEAR_MONTH_DAY_PATTERN) &&
        filter?.pmsSyncOff === !pmsSyncToggle &&
        filter?.variable === paceVariable
    )
    .map((data) => capitalizeFirst(data.label));
  //
  return paceForecastFilter;
};

/**
 * Generate power bi filter schema for pace forecast filters
 * @param {Array} visualFilters - List of registered visual filters
 * @param {Array} paceForecast - List of pace forecast values
 * @returns
 */
export const getPaceForecastSchema = (visualFilters, paceForecast) => {
  let paceForecastFilter;
  const paceForecastDetails = visualFilters?.filter((filter) =>
    filter?.tags?.includes(TAGS.PACE_FORECAST)
  );
  if (paceForecastDetails?.length) {
    const paceForecastValues = paceForecast?.map((item) => item.toLowerCase());
    paceForecastFilter = !isEmpty(paceForecast)
      ? nameFilter(paceForecastDetails[0]?.tableName, paceForecastDetails[0]?.columnName, [
          ACTUAL_VALUE,
          ...paceForecastValues,
        ])
      : nameFilter(paceForecastDetails[0]?.tableName, paceForecastDetails[0]?.columnName, [
          ACTUAL_VALUE,
        ]);
  }
  //
  return paceForecastFilter;
};

/**
 * Generate power bi filter schema for breakdown and focus on filters
 * @param {Array} visualFilters - List of registered visual filters
 * @param {String} chartBreakdown  - selected breakdown values
 * @param {Boolean} isDetailed - state of detailed toggle
 * @param {Array} focusOn - selected focus on values
 * @returns
 */
export const getBreakdownAndFocusOnSchema = (
  visualFilters,
  chartBreakdown,
  isDetailed,
  focusOn
) => {
  const breakdownName = visualFilters?.filter((filter) => filter?.id?.includes(chartBreakdown))[0]
    ?.type;
  const breakdownVisual = visualFilters?.filter(
    (filter) => filter?.tags?.includes(TAGS.AGGREGATION) && filter?.type === breakdownName
  );
  let segmentFilter;
  let focusOnFilter;
  if (breakdownVisual?.length) {
    const type = nameFilter(breakdownVisual[0]?.tableName, breakdownVisual[0]?.columnName, [
      breakdownVisual[0]?.type,
    ]);
    const subType = nameFilter(breakdownVisual[0]?.tableName, breakdownVisual[0]?.columnName, [
      breakdownVisual?.[0]?.values?.[0]?.value,
    ]);
    if (isDetailed) {
      if (breakdownVisual?.[0]?.tags?.includes(TAGS.DETAILED)) {
        segmentFilter = type;
      } else {
        segmentFilter = subType;
      }
    } else if (breakdownVisual?.[0]?.tags?.includes(TAGS.DETAILED)) {
      segmentFilter = subType;
    } else {
      segmentFilter = type;
    }
    const focusOnValues = focusOn?.map((item) => item?.label);
    focusOnFilter = focusOnValues?.length
      ? nameFilter(breakdownVisual[0]?.tableName, breakdownVisual[0]?.values[0]?.columnName, [
          ...focusOnValues,
          KEYS.SET_A,
          KEYS.SET_B,
        ])
      : allFilter(breakdownVisual[0]?.tableName, COLUMN_NAMES.FOCUS_ON_SEGMENT);
  }

  return [segmentFilter, focusOnFilter];
};

/**
 * Generate power bi filter schema for breakdown and focus on filters
 * @param {Array} visualFilters - List of registered visual filters
 * @param {String} chartBreakdown  - selected breakdown values
 * @param {Boolean} isDetailed - state of detailed toggle
 * @param {Array} focusOn - selected focus on values
 * @returns
 */
export const getTimeSegmentBreakdownAndFocusOnSchema = (
  visualFilters,
  chartBreakdown,
  isDetailed,
  focusOn
) => {
  const breakdownName = visualFilters?.filter((filter) => filter?.id?.includes(chartBreakdown))[0]
    ?.type;
  const breakdownVisual = visualFilters?.filter(
    (filter) => filter?.tags?.includes(TAGS.AGGREGATION) && filter?.type === breakdownName
  );
  const segmentFocusOn = visualFilters?.filter(
    (filter) => filter?.tags?.includes(TAGS.SEGMENT_WISE_BREAKDOWN) && filter?.columnName === 'Type'
  )[0];

  let segmentFilter;
  let focusOnFilter;
  let segmentFocusOnFilter;

  if (breakdownVisual?.length) {
    const type = nameFilter(breakdownVisual[0]?.tableName, breakdownVisual[0]?.columnName, [
      breakdownVisual[0]?.type,
    ]);
    const subType = nameFilter(breakdownVisual[0]?.tableName, breakdownVisual[0]?.columnName, [
      breakdownVisual?.[0]?.values?.[0]?.value,
    ]);
    if (isDetailed) {
      if (breakdownVisual?.[0]?.tags?.includes(TAGS.DETAILED)) {
        segmentFilter = type;
      } else {
        segmentFilter = subType;
      }
    } else if (breakdownVisual?.[0]?.tags?.includes(TAGS.DETAILED)) {
      segmentFilter = subType;
    } else {
      segmentFilter = type;
    }

    segmentFocusOnFilter = nameFilter(
      segmentFocusOn?.tableName,
      segmentFocusOn?.columnName,
      segmentFilter?.values
    );

    const focusOnValues = focusOn?.map((item) => item?.label);
    focusOnFilter = focusOnValues?.length
      ? nameFilter(
          TREND_TIME_X_SEGMENT_FILTER.TABLE_NAME,
          COLUMN_NAMES.FOCUS_ON_SEGMENT,
          focusOnValues
        )
      : allFilter(TREND_TIME_X_SEGMENT_FILTER.TABLE_NAME, COLUMN_NAMES.FOCUS_ON_SEGMENT);
  }

  return [segmentFilter, focusOnFilter, segmentFocusOnFilter];
};
/**
 * Generate power bi filter schema for chart breakdown filters
 * @param {Array} visualFilters - List of registered visual filters
 * @param {String} chartBreakdown  - selected breakdown values
 * @param {Boolean} isDetailed - state of detailed toggle
 * @param {String} tag - tag
 * @returns
 */
export const getChartBreakDownSchema = (visualFilters, chartBreakdown, isDetailed, tag) => {
  const chartVisual = visualFilters?.find((filter) => filter?.id?.includes(chartBreakdown));
  const summaryChartVisual = visualFilters?.find((filter) => filter?.tags?.includes(tag));
  let chartBreakdownFilter;
  if (chartVisual) {
    const type = nameFilter(summaryChartVisual?.tableName, summaryChartVisual?.columnName, [
      chartVisual?.type,
    ]);
    const subType = nameFilter(summaryChartVisual?.tableName, summaryChartVisual?.columnName, [
      chartVisual?.values?.[0]?.value,
    ]);
    if (isDetailed) {
      if (chartVisual?.tags?.includes(TAGS.DETAILED)) {
        chartBreakdownFilter = type;
      } else {
        chartBreakdownFilter = subType;
      }
    } else if (chartVisual?.tags?.includes(TAGS.DETAILED)) {
      chartBreakdownFilter = subType;
    } else {
      chartBreakdownFilter = type;
    }
  }
  return chartBreakdownFilter;
};

/**
 * Generate power bi filter schema for chain name filters
 * @param {Array} visualFilters - List of registered visual filters
 * @returns
 */
export const getChainNameSchema = (visualFilters) => {
  const chainNameVisual = visualFilters?.filter((filter) =>
    filter?.tags?.includes(TAGS.CHAIN_NAME)
  );
  let chainNameFilter;
  if (chainNameVisual?.length) {
    chainNameFilter = allFilter(chainNameVisual[0]?.tableName, chainNameVisual[0]?.columnName);
  }

  return chainNameFilter;
};

/**
 * Generate power bi filter schema for metric collection filters
 * @param {Array} visualFilters - List of registered visual filters
 * @param {String} metricSelection - selected metric value
 * @returns
 */
export const getMetricSelectionSchema = (visualFilters, metricSelection) => {
  const metricVisualFilter = visualFilters?.find((filter) =>
    filter?.tags?.includes(TAGS.METRIC_SELECTION)
  );
  let metricSelectionFilter = [];
  if (metricVisualFilter) {
    metricSelectionFilter = nameFilter(
      metricVisualFilter?.tableName,
      metricVisualFilter?.columnName,
      [metricSelection]
    );
  }
  return metricSelectionFilter;
};

/**
 * Generate power bi filter schema for hotel name order filters
 * @param {Array} visualFilters - List of registered visual filters
 * @param {Boolean} isDemoUser - state of demo user
 * @returns
 */
export const getHotelNameOrderSchema = (visualFilters, isDemoUser) => {
  const hotelNameOrder = visualFilters?.find((filter) =>
    filter?.tags?.includes(TAGS.HOTEL_NAME_ORDER)
  );
  const hotelNameOrderFilter = nameFilter(hotelNameOrder?.tableName, hotelNameOrder?.columnName, [
    isDemoUser ? 1 : 0,
  ]);
  //
  return hotelNameOrderFilter;
};

/**
 * Generate power bi filter schema for web clicks steps filters
 * @param {Array} visualFilters - List of registered visual filters
 * @param {Array} webClickStep - List of selected web clicks steps
 * @returns
 */
export const getWebClicksStepsSchema = (visualFilters, webClickStep) => {
  const webClickStepsVisual = visualFilters?.find((filter) =>
    filter?.tags?.includes(TAGS.CLICK_STEPS)
  );
  let webClickStepsFilter;
  if (webClickStepsVisual) {
    const webClickValues = webClickStep?.map((item) => item?.label);
    webClickStepsFilter = webClickValues?.length
      ? nameFilter(webClickStepsVisual?.tableName, webClickStepsVisual?.columnName, [
          ...webClickValues,
        ])
      : allFilter(webClickStepsVisual?.tableName, webClickStepsVisual?.columnName);
  }
  //
  return webClickStepsFilter;
};

/**
 * Generate power bi filter schema for channel focus on filters
 * @param {Array} visualFilters - List of registered visual filters
 * @param {Array} focusOn - List of selected focus on values
 * @returns
 */
export const getChannelFocusOnSchema = (visualFilters, focusOn) => {
  let ChannelFocusOnFilter;
  const channelFocusOnVisual = visualFilters?.find((filter) =>
    filter?.tags?.includes(TAGS.WEB_CLICK_CHANNEL)
  );
  if (channelFocusOnVisual) {
    const channelFocusOnValues = focusOn?.map((item) => item?.label);
    ChannelFocusOnFilter = channelFocusOnValues?.length
      ? nameFilter(channelFocusOnVisual?.tableName, channelFocusOnVisual?.columnName, [
          ...channelFocusOnValues,
        ])
      : allFilter(channelFocusOnVisual?.tableName, channelFocusOnVisual?.columnName);
  }
  //
  return ChannelFocusOnFilter;
};

/**
 * Generate power bi filter schema for scale factor filters
 * @param {Array} visualFilters - List of registered visual filters
 * @param {Number} scalingFactor - scale value
 * @returns
 */
export const getScaleFactorSchema = (visualFilters, scalingFactor) => {
  let scaleFactorFilter;
  const scaleFactorVisual = visualFilters?.find((filter) =>
    filter?.tags?.includes(TAGS.SCALING_FACTOR)
  );
  if (scaleFactorVisual) {
    scaleFactorFilter = nameFilter(scaleFactorVisual?.tableName, scaleFactorVisual?.columnName, [
      scalingFactor,
    ]);
  }
  //
  return scaleFactorFilter;
};

/**
 * Generate power bi filter schema for normalized view filters
 * @param {Array} visualFilters - List of registered visual filters
 * @param {Boolean} normalizedViewToggle - state of normalized view toggle
 * @returns
 */
export const getNormalizedViewSchema = (visualFilters, normalizedViewToggle) => {
  let normalizedFilter = [];
  const normalizedVisual = visualFilters?.filter((filter) =>
    filter?.tags.includes(TAGS.NORMALIZED)
  );
  if (normalizedVisual?.length) {
    normalizedFilter = nameFilter(normalizedVisual[0]?.tableName, normalizedVisual[0]?.columnName, [
      normalizedViewToggle,
    ]);
  }
  //
  return normalizedFilter;
};

/**
 * Generate power bi filter schema for utm breakdown filters
 * @param {Array} visualFilters - List of registered visual filters
 * @param {String} utmBreakdown - utm breakdown value
 * @returns
 */
export const getUtmBreakdownSchema = (visualFilters, utmBreakdown) => {
  let utmBreakdownFilter;
  const utmBreakdownVisualFilter = visualFilters?.find((filter) => filter?.id === utmBreakdown);
  if (utmBreakdownVisualFilter) {
    utmBreakdownFilter = nameFilter(
      utmBreakdownVisualFilter?.tableName,
      utmBreakdownVisualFilter?.columnName,
      utmBreakdownVisualFilter?.values
    );
  }
  //
  return utmBreakdownFilter;
};

/**
 * Generate power bi filter schema for utm focus on filters
 * @param {Array} visualFilters - List of registered visual filters
 * @param {Array} utmFocusOn - List of selected utm focus on values
 * @returns
 */
export const getUtmFocusOnSchema = (visualFilters, utmFocusOn) => {
  let UtmFocusOnFilter;
  const utmFocusOnVisualFilter = visualFilters?.find((filter) =>
    filter?.tags?.includes(TAGS.UTM_FOCUS_ON)
  );
  if (utmFocusOn) {
    const utmFocusOnValues = utmFocusOn?.map((item) => item?.label);
    if (utmFocusOnVisualFilter) {
      UtmFocusOnFilter = utmFocusOnValues?.length
        ? nameFilter(
            utmFocusOnVisualFilter?.tableName,
            utmFocusOnVisualFilter?.columnName,
            utmFocusOnValues
          )
        : allFilter(utmFocusOnVisualFilter?.tableName, utmFocusOnVisualFilter?.columnName);
    }
  } else
    UtmFocusOnFilter = allFilter(
      utmFocusOnVisualFilter?.tableName,
      utmFocusOnVisualFilter?.columnName
    );
  //
  return UtmFocusOnFilter;
};

/**
 * Generate  power bi filter schema for regional map pms sync filter
 * @param {Array} visualFilters - List of registered visual filters
 * @param {Boolean} pmsSyncToggle - Pms sync toggle state
 * @returns
 */
export const getPmsSyncToggleSchema = (visualFilters, pmsSyncToggle) => {
  let pmsSyncFilter = [];
  const pmsSyncVisual = visualFilters?.filter((filter) => filter?.tags?.includes(TAGS.PMS_SYNC));
  if (!pmsSyncToggle) {
    pmsSyncFilter = pmsSyncVisual?.map((item) =>
      nameFilter(item?.tableName, item?.columnName, item?.values, OPERATORS.NOT_IN)
    );
  } else {
    pmsSyncFilter = pmsSyncVisual?.map((item) => allFilter(item?.tableName, item?.columnName));
  }
  return pmsSyncFilter;
};
/**
 * Generate  power bi filter schema for regional map  trend hotel pms sync filter
 * @param {Array} visualFilters - List of registered visual filters
 * @param {Boolean} pmsSyncToggle - Pms sync toggle state
 * @returns
 */
export const getTrendHotelPmsSyncToggleSchema = (visualFilters, pmsSyncToggle) => {
  let pmsSyncFilter = [];
  const pmsSyncVisual = visualFilters?.filter((filter) =>
    filter?.tags?.includes(TAGS.TREND_HOTEL_PMS_SYNC)
  );
  pmsSyncFilter = pmsSyncVisual?.map((item) =>
    nameFilter(item?.tableName, item?.columnName, [!pmsSyncToggle])
  );
  return pmsSyncFilter;
};

/**
 * Generate power bi filter schema for regional map breakdown and focus on filters
 * @param {Array} visualFilters - List of registered visual filters
 * @param {String} chartBreakdown  - selected breakdown values
 * @param {Boolean} isDetailed - state of detailed toggle
 * @param {Array} focusOn - selected focus on values
 * @returns
 */
export const getBreakdownAndFocusOnSchemaByBreakdown = (
  visualFilters,
  chartBreakdown,
  isDetailed,
  focusOn
) => {
  const breakdownVisual = visualFilters?.filter((filter) => filter?.id?.includes(chartBreakdown));
  let segmentFilter;
  let focusOnFilter;
  if (breakdownVisual?.length) {
    const type = nameFilter(breakdownVisual[0]?.tableName, breakdownVisual[0]?.columnName, [
      breakdownVisual[0]?.type,
    ]);
    const subType = nameFilter(breakdownVisual[0]?.tableName, breakdownVisual[0]?.columnName, [
      breakdownVisual?.[0]?.values?.[0]?.value,
    ]);
    if (isDetailed) {
      if (breakdownVisual?.[0]?.tags?.includes(TAGS.DETAILED)) {
        segmentFilter = type;
      } else {
        segmentFilter = subType;
      }
    } else if (breakdownVisual?.[0]?.tags?.includes(TAGS.DETAILED)) {
      segmentFilter = subType;
    } else {
      segmentFilter = type;
    }
    const focusOnValues = focusOn?.map((item) => item?.label);
    focusOnFilter = focusOnValues?.length
      ? nameFilter(
          breakdownVisual[0]?.tableName,
          breakdownVisual[0]?.values[0]?.columnName,
          focusOnValues
        )
      : allFilter(breakdownVisual[0]?.tableName, COLUMN_NAMES.FOCUS_ON_SEGMENT);
  }
  //
  return [segmentFilter, focusOnFilter];
};

/**
 * Generate power bi filter schema for group A filters
 * @param {Array} visualFilters - List of registered visual filters
 * @returns
 */
export const getGroupASchema = (visualFilters) => {
  const groupAFilter = visualFilters?.find((item) => item.tags?.includes(TAGS.HOTEL_GROUP_2));
  //
  return groupAFilter;
};

/**
 * Generate power bi filter schema for hotel group A filters
 * @param {Array} focusOnValues - list of focus on values
 * @param {Object} selectedHotel - selected hotel
 * @param {Object} groupAFilter - group A filter schema
 * @param {Boolean} isTrendHotelAggregate - state of is trend hotel aggregate
 * @returns
 */
export const getHotelGroupASchema = (focusOnValues) => {
  const hotelGroupAFilter = nameFilter('Group_2_hotel', 'HotelID', focusOnValues);
  //
  return hotelGroupAFilter;
};

/**
 * Generate power bi filter schema for group A filters
 * @param {Array} visualFilters - List of registered visual filters
 * @returns
 */
export const getGroupHotelFilterSchema = (visualFilters) => {
  const groupHotelFilter = visualFilters?.find((item) => item.tags?.includes(TAGS.HOTEL_GROUP_1));
  //
  return groupHotelFilter;
};

/**
 * Generate power bi filter schema for hotel group A filters
 * @param {Array} focusOnValues - list of focus on values
 * @param {Object} groupHotelFilter - group A filter schema
 * @returns
 */
export const getHotelGroupSchema = (focusOnValues) => {
  const hotelGroupFilter = nameFilter('Group_1_hotel', 'HotelID', focusOnValues);
  //
  return hotelGroupFilter;
};

/**
 * Generate power bi filter schema for hotel group order filters
 * @param {Array} visualFilters - List of registered visual filters
 * @param {Boolean} isDemoUser - demo user state
 * @returns
 */
export const getHotelGroupOrderSchema = (visualFilters, isDemoUser) => {
  const hotelGroupOrder = visualFilters?.find((filter) =>
    filter?.tags?.includes(TAGS.HOTEL_GROUP_ORDER)
  );
  const hotelGroupOrderFilter = nameFilter(
    hotelGroupOrder?.tableName,
    hotelGroupOrder?.columnName,
    [isDemoUser ? 1 : 0]
  );
  //
  return hotelGroupOrderFilter;
};

/**
 * Generate power bi filter schema for moving average filters
 * @param {Array} visualFilters - List of registered visual filters
 * @param {string} movingAverage - Currently selected moving average
 * @returns
 */
export const getMovingAverageSchema = (visualFilters, movingAverage) => {
  const movingAverageFilterObject = visualFilters?.find(
    (filter) => filter?.id === movingAverage?.id
  );
  let filter;
  if (movingAverageFilterObject) {
    filter = nameFilter(
      movingAverageFilterObject?.tableName,
      movingAverageFilterObject?.columnName,
      movingAverageFilterObject?.values?.map((value) => parseInt(value, 10))
    );
  }
  //
  return filter;
};

/**
 * Generate power bi filter schema for global currency filter
 * @param {Array} visualFilters - List of registered visual filters
 * @param {string} currency - Currently selected currency
 * @returns
 */
export const getCurrencySchema = (visualFilters, currency = 'USD') => {
  const currencyFilterObject = visualFilters?.find((filter) =>
    filter?.tags?.includes(TAGS.CURRENCY)
  );
  let filter;
  if (currencyFilterObject) {
    filter = nameFilter(currencyFilterObject?.tableName, currencyFilterObject?.columnName, [
      currency,
    ]);
  }
  //
  return filter;
};

/**
 * Generate power bi filter schema for group 1 and 2 filters
 * @param {Array} visualFilters - List of registered visual filters
 * @param {Boolean} isDetailed - state of detailed toggle
 * @param {Array} segmentGroup1 -  list of segment group 1 values
 * @param {Array} segmentGroup2 - list of segment group 1 values
 * @returns
 */
export const getGroup1And2Schema = (visualFilters, isDetailed, segmentGroup1, segmentGroup2) => {
  let group1Filter;
  let group2Filter;
  const group1 = visualFilters?.filter((filter) => filter?.tags?.includes(TAGS.SEGMENT_GROUP_1))[0];
  const group2 = visualFilters?.filter((filter) => filter?.tags?.includes(TAGS.SEGMENT_GROUP_2))[0];
  if (isDetailed) {
    if (segmentGroup1) {
      const values = segmentGroup1?.map((item) => item?.label);
      group1Filter = values?.length
        ? nameFilter(group1?.tableName, group1?.columnName, values)
        : allFilter(group1?.tableName, COLUMN_NAMES.FOCUS_ON_SEGMENT);
    }
    if (segmentGroup2) {
      const values = segmentGroup2?.map((item) => item?.label);
      group2Filter = values?.length
        ? nameFilter(group2?.tableName, group2?.columnName, values)
        : allFilter(group2?.tableName, COLUMN_NAMES.FOCUS_ON_SEGMENT);
    }
  } else {
    group1Filter = allFilter(group1?.tableName, COLUMN_NAMES.FOCUS_ON_SEGMENT);
    group2Filter = allFilter(group2?.tableName, COLUMN_NAMES.FOCUS_ON_SEGMENT);
  }

  return [group1Filter, group2Filter];
};

/**
 * Generate power bi filter schema for group 1 and 2 filters
 * @param {Array} visualFilters - List of registered visual filters
 * @param {Array} segmentGroup1 -  list of segment group 1 values
 * @param {Array} segmentGroup2 - list of segment group 1 values
 * @returns
 */
export const getTrendGroup1And2Schema = (visualFilters, segmentGroup1, segmentGroup2) => {
  let group1Filter;
  let group2Filter;
  const group1 = visualFilters?.filter((filter) => filter?.tags?.includes(TAGS.SEGMENT_GROUP_1))[0];
  const group2 = visualFilters?.filter((filter) => filter?.tags?.includes(TAGS.SEGMENT_GROUP_2))[0];
  if (segmentGroup1) {
    const values = segmentGroup1?.map((item) => item?.label);
    group1Filter = values?.length
      ? nameFilter(group1?.tableName, group1?.columnName, values)
      : allFilter(group1?.tableName, COLUMN_NAMES.FOCUS_ON_SEGMENT);
  }
  if (segmentGroup2) {
    const values = segmentGroup2?.map((item) => item?.label);
    group2Filter = values?.length
      ? nameFilter(group2?.tableName, group2?.columnName, values)
      : allFilter(group2?.tableName, COLUMN_NAMES.FOCUS_ON_SEGMENT);
  }
  return [group1Filter, group2Filter];
};

/**
 * Generate power bi filter schema for trend time alignment and date comparison filters
 * @param {Array} visualFilters - List of registered visual filters
 * @param {String} alignmentId - selected alignment id
 * @param {String} dateComparison - selected date comparison value
 * @param {Date} endDateComparison - end date comparison value
 * @returns
 */
export const getTrendTimeAlignmentAndDateComparisonSchema = (
  visualFilters,
  alignmentId,
  dateComparison,
  endDateComparison
) => {
  const alignmentVisual = visualFilters?.filter((filter) => filter?.id?.includes(alignmentId));
  let alignmentFilter;
  if (alignmentVisual?.length) {
    alignmentFilter = nameFilter(alignmentVisual[0]?.tableName, alignmentVisual[0]?.columnName, [
      NA,
      capitalizeFirst(alignmentVisual[0]?.type),
    ]);
  }
  const dateComparisonVisual = visualFilters?.filter((filter) => filter?.id?.includes(alignmentId));
  let dateComparisonFilter;
  if (dateComparison === KEYS.CUSTOM && endDateComparison === null) return alignmentFilter;
  if (dateComparisonVisual?.length) {
    dateComparisonFilter = nameFilter(
      dateComparisonVisual[0]?.tableName,
      dateComparisonVisual[0]?.values[0].columnName,
      [dateComparison]
    );
  }
  //
  return [alignmentFilter, dateComparisonFilter];
};

/**
 * Get selected sub breakdown filter
 * @param {Array} visualFilters - List of registered visual filters
 * @param {String} tag - tag
 * @returns
 */
export const getSubBreakdownFilter = (visualFilters, tag = null) => {
  let subBreakdownFilter;
  if (tag !== null) {
    subBreakdownFilter = visualFilters
      ?.filter((filter) => filter?.tags?.includes(TAGS.SEGMENT_BREAKDOWN && tag))
      .map((data) => ({
        id: data?.id,
        label: data?.values[0]?.label,
      }));
  } else {
    subBreakdownFilter = visualFilters
      ?.filter((filter) => filter?.tags?.includes(TAGS.SEGMENT_BREAKDOWN))
      .map((data) => ({
        label: data?.values[0]?.label,
        id: data?.id,
      }));
  }
  //
  return subBreakdownFilter;
};

/**
 * Get selected sub selection metric filter
 * @param {Array} visualFilters - List of registered visual filters
 * @returns
 */
export const getSubSelectionMetricFilter = (visualFilters) => {
  const subSelectionMetricFilter = visualFilters
    ?.filter((filter) => filter?.tags?.includes(TAGS.METRIC_SELECTION))
    .map((data) => ({
      label: data?.values[0],
      id: data?.id,
    }));
  //
  return subSelectionMetricFilter;
};

/**
 * Get selected sub time frame filter
 * @param {Array} visualFilters - List of registered visual filters
 * @returns
 */
export const getSubTimeFrameFilter = (visualFilters) => {
  const subTimeFrameFilter = visualFilters
    ?.filter((filter) => filter?.tags?.includes(TAGS.I_PREFER_TIME_FRAME))
    .map((data) => ({
      label: data?.values[0],
      id: data?.id,
    }));
  //
  return subTimeFrameFilter;
};

/**
 * Get selected date comparison filter
 * @param {Array} visualFilters - List of registered visual filters
 * @param {String} alignmentId - selected alignment id
 * @returns
 */
export const getDateComparisonFilter = (visualFilters, alignmentId) => {
  const dateComparisonFilter = visualFilters
    ?.filter((filter) => filter?.id === alignmentId)[0]
    ?.values?.map((data, index) => ({
      label: data?.label,
      id: index,
      isDefault: data?.isDefault,
      value: data?.value,
    }));
  //
  return dateComparisonFilter;
};

/**
 * Get selected sub alignment filter
 * @param {Array} visualFilters - List of registered visual filters
 * @param {Boolean} isDefaultState - is Default state
 * @returns
 */
export const getSubAlignmentFilter = (visualFilters, alignmentToggle) => {
  const filterType = alignmentToggle ? ALIGNED : 'UNALIGNED';
  const subAlignmentFilter = visualFilters?.find(
    (filter) => filter?.tags?.includes(TAGS.DATE_COMPARISON) && filter?.type === filterType
  );
  //
  return subAlignmentFilter;
};

/**
 * Get selected focus on filter
 * @param {Array} breakdownData - list of breakdown data
 * @param {String} selectedBreakdown - selected breakdown vlaue
 * @returns
 */
export const getFocusOnFilter = (breakdownData, selectedBreakdown, isOverall = false) => {
  const focusOnFilter = isOverall ? [{ label: '\u200BOverall', id: 0 }] : [];
  breakdownData
    ?.filter(
      (filter) => capitalizeFirst(filter?.breakdownType) === capitalizeFirst(selectedBreakdown)
    )
    .forEach((breakdown) =>
      breakdown?.subCategory?.forEach((data) =>
        focusOnFilter.push({
          label: data,
          id: focusOnFilter.length + 1,
        })
      )
    );
  const focusOnFilterValues = focusOnFilter.filter(
    (obj, index, array) => array.findIndex((o) => o.label === obj.label) === index
  );
  // Sort the focusOnFilterValues by 'label', excluding "Overall" if it exists
  if (isOverall) {
    // Keep 'Overall' at the first position and sort the rest
    const sortedValues = focusOnFilterValues
      .slice(1)
      .sort((a, b) => a.label.localeCompare(b.label));
    focusOnFilterValues.splice(1, focusOnFilterValues.length - 1, ...sortedValues);
  } else {
    // Sort all items by 'label' if 'Overall' is not present
    focusOnFilterValues.sort((a, b) => a.label.localeCompare(b.label));
  }
  //
  return focusOnFilterValues;
};

/**
 * set compare window command value on command flow
 * @param {String} value  - command value (py | pw | otb | pm)
 * @param {Boolean} customBooking - state of enable custom booking
 * @param {Function} handleComparisonChange - Function to set comparison change
 */
export const setCompareWindowCommandValue = (value, customBooking, handleComparisonChange) => {
  if (compareWindows[value] !== OTB_VALUE && !customBooking) {
    if (compareWindows[value] !== undefined) {
      handleComparisonChange({
        target: {
          value: compareWindows[value],
        },
      });
    }
  } else if (compareWindows[value] !== undefined && customBooking) {
    handleComparisonChange({
      target: {
        value: compareWindows[value],
      },
    });
  }
};
/**
 * Get selected time frame option from time frame list
 * @param {Array} timeFrameList - list of time frame options
 * @param {String} timeFrameValueCommand - command value
 * @returns
 */
export const getTimeFrameOption = (timeFrameList, timeFrameValueCommand) => {
  const timeFrameOption = timeFrameList?.find((val) =>
    val?.label
      ?.trim()
      ?.replace(/ +/g, '')
      .toLowerCase()
      .includes(timeFrameValueCommand?.trim()?.replace(/ +/g, '').toLowerCase())
  );
  //
  return timeFrameOption;
};

/**
 * Get selected sub pace filter
 * @param {Array} visualFilters - List of registered visual filters
 * @returns
 */
export const getSubPaceFilter = (visualFilters) => {
  const subPaceFilter = visualFilters
    ?.filter((filter) => filter?.tags?.includes(TAGS.PACE_HORIZON))
    .map((data) => ({
      label: data?.values,
      id: data?.id,
    }));
  //
  return subPaceFilter;
};

/**
 * Get selected sub utm breakdown filter
 * @param {Array} visualFilters - List of registered visual filters
 * @returns
 */
export const getSubUtmBreakdownFilter = (visualFilters) => {
  const subUtmBreakdownFilter = visualFilters
    ?.filter((filter) => filter?.tags?.includes(TAGS.UTM_BREAKDOWN))
    .map((data) => ({
      label: data?.values[0],
      id: data?.id,
    }));
  //
  return subUtmBreakdownFilter;
};

/**
 * Get selected breakdown
 * @param {Array} visualFilters - List of registered visual filters
 * @param {String} chartBreakdown - chart breakdown value
 * @returns
 */
export const getBreakdown = (visualFilters, chartBreakdown) =>
  visualFilters?.find((filter) => filter?.id === chartBreakdown)?.values?.[0]?.value;

/**
 * Get selected utm breakdown
 * @param {Array} visualFilters - List of registered visual filters
 * @param {String} utmBreakdown - utm breakdown value
 * @returns
 */
export const getUtmBreakdown = (visualFilters, utmBreakdown) =>
  visualFilters?.find((filter) => filter?.id === utmBreakdown);

/**
 * Get selected utm focus on filter
 * @param {Array} utmBreakdownData - list of utm breakdown data
 * @param {String} selectedUtmBreakdown - selected breakdown value
 * @returns
 */
export const getUtmFocusOnFilter = (utmBreakdownData, selectedUtmBreakdown) => {
  const focusOnFilter =
    utmBreakdownData
      ?.filter(
        (filter) =>
          capitalizeFirst(filter?.breakdownType) ===
          capitalizeFirst(selectedUtmBreakdown?.values[0])
      )[0]
      ?.subCategory?.map((data, index) => ({
        label: data,
        id: index,
      })) ?? [];
  //
  return focusOnFilter;
};

/**
 * Get grouping A focus on values on trend hotels filter pane
 * @param {Array} hotelList - list of hotels
 * @param {Object} selectedHotelName - selected hotel name
 * @param {Array} focusOn - list of selected focus on values
 * @param {Boolean} isDemoUser - is demo user state
 * @returns
 */
export const getGroupingAFocusOnValues = (hotelList, selectedHotelName, focusOn, isDemoUser) => {
  const selectedHotel = hotelList?.find(
    (filter) =>
      filter.label === selectedHotelName?.label || filter.label === selectedHotelName?.altName
  );
  const focusOnValues = focusOn?.map((item) => (isDemoUser ? item?.altName : item?.label));
  const groupingA = focusOnValues.filter(
    (element) => [selectedHotel?.label, selectedHotel?.altName].indexOf(element) < 0
  );

  return groupingA;
};

/**
 * Get sub pace variable filter
 * @param {Array} visualFilters - List of registered visual filters
 * @returns
 */
export const getSubPaceVariableFilter = (visualFilters) => {
  const subPaceVariableFilter = visualFilters
    ?.filter((filter) => filter?.tags?.includes(TAGS.PACE_VARIABLE))
    .map((data) => ({
      label: data?.values?.[0],
      id: data?.id,
    }));
  //
  return subPaceVariableFilter;
};

/**
 * Get percentage items list
 * @param {Array} visualFilters - List of registered visual filters
 * @returns
 */
export const getPercentageList = (visualFilters) => {
  const percentageItemList = visualFilters
    ?.filter((filter) => filter?.tags?.includes(TAGS.PERCENTAGE))
    .map((data) => ({
      label: `${data?.values}%`,
      id: data?.id,
    }));
  //
  return percentageItemList;
};

/**
 * Get time frame filter
 * @param {Array} visualFilters - List of registered visual filters
 * @param {String} timeFrame - time frame value
 * @returns
 */
export const getTimeFrameFilter = (visualFilters, timeFrame) => {
  const timeFrameVisualFilter = visualFilters?.find((filter) => filter?.id === timeFrame)
    ?.values[0];
  //
  return timeFrameVisualFilter;
};

/**
 * Get segment filter based on selected breakdown
 * @param {Array} visualFilters - List of registered visual filters
 * @param {String} timeFrame - time frame value
 * @returns
 */
export const getSegmentFocusOnSchema = (selectedBreakdown, focusOn) => {
  let column;
  switch (selectedBreakdown) {
    case 'Channel':
      column = TREND_TIME_SEGMENT_FILTER.CHANNEL;
      break;
    case 'Channel (raw)':
      column = TREND_TIME_SEGMENT_FILTER.CHANNEL_RAW;
      break;
    case 'Market Segment':
      column = TREND_TIME_SEGMENT_FILTER.MARKET_SEGMENT;
      break;
    case 'Market Segment (raw)':
      column = TREND_TIME_SEGMENT_FILTER.MARKET_SEGMENT_RAW;
      break;
    case 'Rate Category':
      column = TREND_TIME_SEGMENT_FILTER.RATE_CATEGORY;
      break;
    case 'Rate Category (raw)':
      column = TREND_TIME_SEGMENT_FILTER.RATE_CATEGORY_RAW;
      break;
    case 'Region':
      column = TREND_TIME_SEGMENT_FILTER.REGION;
      break;
    case 'Region (raw)':
      column = TREND_TIME_SEGMENT_FILTER.REGION_RAW;
      break;
    default:
      break;
  }
  return nameFilter(TREND_TIME_SEGMENT_FILTER.TABLE_NAME, column, focusOn);
};

export const trendTimeSegmentDailyPreset = {
  rows: {
    header: [11, 12],
  },
  columns: {
    width: [25, ...Array(44).fill(12)],
    end: 'S',
  },
  table: {
    start: 11,
    end: 44,
    dataStart: 13,
  },
  totalrow: true,
  bordered: true,
};
//
export const trendTimeSegmentWeeklyPreset = {
  rows: {
    header: [11, 12],
  },
  columns: {
    width: [25, ...Array(44).fill(12)],
    end: 'S',
  },
  table: {
    start: 11,
    end: 44,
    dataStart: 13,
  },
  totalrow: true,
  bordered: true,
};
//
export const trendTimeSegmentCumulativePreset = {
  rows: {
    header: [11, 12],
  },
  columns: {
    width: [25, 10, ...Array(44).fill(12)],
    end: 'J',
  },
  table: {
    start: 11,
    end: 44,
    dataStart: 13,
  },
  totalrow: true,
  bordered: true,
};
